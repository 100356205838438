import lss from './assets/images/tokens/lss.jpg';
import dolphins from './assets/images/tokens/dolphins.png';
import ydragon from './assets/images/tokens/ydragon.jpeg';
import gotem from './assets/images/tokens/gotem.jpeg';
import revolve from './assets/images/tokens/revolve.png';
import aag from './assets/images/tokens/aag.png';
import aimedis from './assets/images/tokens/aimedis.png';
import cornucopias from './assets/images/tokens/cornucopias.png';
import streetRunner from './assets/images/tokens/street-runner.png';
import peoplez from './assets/images/tokens/peoplez.png';

export interface Token {
  address: string;
  name: string;
  displayName: string;
  chainId: number;
  decimals: number;
  symbol: string;
  logoURI: string;
}

export const tokens: Token[] = [
  {
    address: '0x3757232b55e60da4a8793183ac030cfce4c3865d',
    name: 'YDragon',
    displayName: 'YDragon',
    decimals: 16,
    chainId: 1,
    symbol: 'YDR',
    logoURI: ydragon,
  },
  {
    address: '0x111ef753378Bc08198A094cAf22e6E9c303b07Ca',
    name: 'TEST',
    displayName: 'TEST',
    decimals: 18,
    chainId: 3,
    symbol: 'TEST',
    logoURI: lss,
  },
  {
    address: '0xc358fc059488109a8c268e7bf7a31aad890cba2c',
    name: 'Dodovano',
    displayName: 'Dodovano',
    decimals: 18,
    chainId: 3,
    symbol: 'DVO',
    logoURI: lss,
  },
  {
    address: '0x3D48F6CD11927924eF0836B56E51C400Bfcc9D47',
    name: 'LOSSTEST',
    displayName: 'LOSSTEST',
    decimals: 18,
    chainId: 3,
    symbol: 'LSST',
    logoURI: lss,
  },
  {
    address: '0x1A51ee437FD6306345E346Cf63bD667D4a55ba13',
    name: 'B',
    displayName: 'B',
    decimals: 18,
    chainId: 3,
    symbol: 'LSST',
    logoURI: lss,
  },
  {
    address: '0xd1d7C5559aBe13C03057ee033FEfA615e9Cebd00',
    name: 'VaultToken2',
    displayName: 'VaultToken2',
    decimals: 18,
    chainId: 3,
    symbol: 'VTK2',
    logoURI: lss,
  },
  {
    address: '0xefB5b03478Cbc91740e1dB11F8458b97f16505d1',
    name: 'C',
    displayName: 'C',
    decimals: 18,
    chainId: 3,
    symbol: 'LSST',
    logoURI: lss,
  },
  {
    address: '0x3df14bfe3d30bd29c78e330fc239bfec797ee85b',
    name: 'ROCATEST',
    displayName: 'ROCATEST',
    decimals: 18,
    chainId: 3,
    symbol: 'ROCA',
    logoURI: lss,
  },
  {
    address: '0xf2d306345eae7405f78d9fe489791df6c38a7b63',
    name: 'ROCAv4',
    displayName: 'ROCAv4',
    decimals: 18,
    chainId: 3,
    symbol: 'ROCAv4',
    logoURI: lss,
  },
  {
    address: '0x3b9be07d622accaed78f479bc0edabfd6397e320',
    name: 'LOSSLESS',
    displayName: 'LOSSLESS',
    decimals: 18,
    chainId: 1,
    symbol: 'LSS',
    logoURI: lss,
  },
  {
    address: '0x5ba19d656b65f1684cfea4af428c23b9f3628f97',
    name: 'AAG Ventures',
    displayName: 'AAG Ventures',
    decimals: 18,
    chainId: 1,
    symbol: 'AAG',
    logoURI: aag,
  },
  {
    address: '0xa5fbc3520dd4bb85fcd175e1e3b994546a2c1ee8',
    name: 'gotEM',
    displayName: 'gotEM',
    decimals: 18,
    chainId: 56,
    symbol: 'GOTEM',
    logoURI: gotem,
  },
  {
    address: '0x01E0d17a533E5930A349C2BB71304F04F20AB12B',
    name: 'Revolve Games',
    displayName: 'Revolve Games',
    decimals: 18,
    chainId: 56,
    symbol: 'RPG',
    logoURI: revolve,
  },
  {
    address: '0xd376F64bb7db90E11e78C91cbd58b756e1B8E7a1',
    name: 'Aimedis',
    displayName: 'Aimedis',
    decimals: 18,
    chainId: 56,
    symbol: 'AIMX',
    logoURI: aimedis,
  },
  {
    address: '0x722f41f6511ff7cda73a1cb0a9ea2f731738c4a0',
    name: 'Street Runner NFT',
    displayName: 'Street Runner NFT',
    decimals: 18,
    chainId: 56,
    symbol: 'SRG',
    logoURI: streetRunner,
  },
  {
    address: '0x722f41f6511ff7cda73a1cb0a9ea2f731738c4a0',
    name: 'Cornucopias',
    displayName: 'Cornucopias',
    decimals: 18,
    chainId: 1,
    symbol: 'COPI',
    logoURI: cornucopias,
  },
  {
    address: '0xc23be03f64a834b3fa6ae62c97ac8b40f3eec6a9',
    name: 'Peoplez',
    displayName: 'Peoplez',
    decimals: 18,
    chainId: 56,
    symbol: 'LEZ',
    logoURI: peoplez,
  },
  {
    address: '0xCd970D95Da2A886E04eaf739D04C0deEe8F37F4D',
    name: 'Dolphins.Finance',
    displayName: 'Dolphins.Finance',
    decimals: 18,
    chainId: 56,
    symbol: 'DOLPH',
    logoURI: dolphins,
  },
  {
    address: '0x5FdB9e0e02c1805FECE41853E892Ec3106f03B87',
    name: 'EASY',
    displayName: 'EASY',
    decimals: 18,
    chainId: 1 | 3,
    symbol: 'EASY',
    logoURI: lss,
  },
  {
    address: '0x1c929Fd22fd1627826Ee781ac77574794Dc9ff1e',
    name: 'A',
    displayName: 'A',
    decimals: 18,
    chainId: 97,
    symbol: 'A',
    logoURI: lss,
  },
  {
    address: '0xeB8b609E981240FEb0f02ba397c5Ed754EF9402A',
    name: 'TKN',
    displayName: 'TKN',
    decimals: 18,
    chainId: 80001,
    symbol: 'TKN',
    logoURI: lss,
  },
  {
    address: '0xD50808A6cd4508390d368879FdeCfd57B314F3a5',
    name: 'SafeBox',
    displayName: 'SafeBox',
    decimals: 18,
    chainId: 3,
    symbol: 'SBX',
    logoURI: lss,
  },
  {
    address: '0xCc7964Abb08bA797Ede299c297C80b3b97b049bf',
    name: 'ROCA_RINK',
    displayName: 'ROCA_RINK',
    decimals: 18,
    chainId: 4,
    symbol: 'ROCA_RINK',
    logoURI: lss,
  },
];

export interface NetworkConfig {
  chainId: number;
  guardian: string;
  singleLimitStrategy: string;
  whiteListStrategy: string;
  controller: string;
  fromBlock: number;
}

export const networkConfigs: NetworkConfig[] = [
  // MAINNET
  {
    chainId: 1,
    guardian: '0x77736D218D05cB3D122107e12dEF22D5e69e2908',
    singleLimitStrategy: '0xF8786f689F83CA69031e7ccffCA55BaC2F59C6c5',
    whiteListStrategy: '0x0b93609b949271BC11d3D9b4976759706bA559B7',
    controller: '0xe91D7cEBcE484070fc70777cB04F7e2EfAe31DB4',
    fromBlock: 12511916,
  },
  // ROPSTEN
  {
    chainId: 3,
    guardian: '0x9b2c9557f511FaF4BBF655F72720eFfD963bE12e',
    singleLimitStrategy: '0xc327002F41fb96Cadf5aCECcD71907D7f471019E',
    whiteListStrategy: '0x3869446a84daB7D7E385FeC0aeb98C5E587127cB',
    controller: '0x27fce20D62f1DE73B0Ae1Dc7572F881061692de9',
    fromBlock: 10911429,
  },
  // RINKEBY
  {
    chainId: 4,
    guardian: '0xff4f138F4136d2475E7e9eEaeD0E314430dDd542',
    singleLimitStrategy: '0x8a44820c28829f700a465Ac9271442416cD2b623',
    whiteListStrategy: '0x675A7c98db0c8b28c55C0Db119c8C465bd4e0fCD',
    controller: '0xb32aa4B6731Ba281A84E34757E41B72f2C090A44',
    fromBlock: 9769581,
  },
  // BSC
  {
    chainId: 56,
    guardian: '0xb9131977954Cf86e33F89C93d8e21e10Dce93929',
    singleLimitStrategy: '0x7A732A846AfF4BCa49F4F77eEA7a851BB35bC82e',
    whiteListStrategy: '0x9c85C5560E830F60BEE57E4109b99307d44488c8',
    controller: '0xDBB5125CEEaf7233768c84A5dF570AeECF0b4634',
    fromBlock: 8974347,
  },
  // {
  //   chainId: 97,
  //   guardian: "0xfd060Cf3E15FeFc4dBD7F9F3D735a4d1AECA8e3d",
  //   singleLimitStrategy: "0x8C95F752eb59acecB0B72B569d8D102e18035AC8",
  //   whiteListStrategy: "0xb6619813C4B701a31C303De70256802E97f0f6Fa",
  //   controller: "0xC46236F780f1294B2C2a8c3cE5B4d62258dC2619",
  //   fromBlock: 13642906,
  // },
  {
    chainId: 80001,
    guardian: '0x4180731dB44947AE644D9a6dA359a882CF7D1740',
    singleLimitStrategy: '0xF601afcc4A2a1f991bf46AE37Fa87C9732aBbF84',
    whiteListStrategy: '0x6E2D0c7714b1b5751e0231927b1f2193acacE011',
    controller: '0x29C2541d25091B565c6999f20549EB2c75b7c269',
    fromBlock: 18163150,
  },
  // MATIC
  {
    chainId: 137,
    guardian: '0xb9131977954Cf86e33F89C93d8e21e10Dce93929',
    singleLimitStrategy: '0x7A732A846AfF4BCa49F4F77eEA7a851BB35bC82e',
    whiteListStrategy: '0x9c85C5560E830F60BEE57E4109b99307d44488c8',
    controller: '0x66622e2C1b991983e88132da19b2C31f71009035',
    fromBlock: 18584638,
  },

  // {
  //   chainId: 1666700000,
  //   guardian: "0x08061AE40E3AB7aFdeD575B2Ba2460F9319eDbE7",
  //   singleLimitStrategy: "0x29C2541d25091B565c6999f20549EB2c75b7c269",
  //   whiteListStrategy: "0xd8b83d5Ad4102dEf6255643e8AB2BA5410D4d0f8",
  //   controller: "0xe91D7cEBcE484070fc70777cB04F7e2EfAe31DB4",
  //   fromBlock: 16775232,
  // },
  // {
  //   chainId: 1666600000,
  //   guardian: "0x9c85C5560E830F60BEE57E4109b99307d44488c8",
  //   singleLimitStrategy: "0x670a32ae6706B8AE42798e1Cc156Fc30139EC894",
  //   whiteListStrategy: "0x7A732A846AfF4BCa49F4F77eEA7a851BB35bC82e",
  //   controller: "0xDBB5125CEEaf7233768c84A5dF570AeECF0b4634",
  //   fromBlock: 18984690,
  // },
  {
    chainId: 43114,
    guardian: '0xb9131977954Cf86e33F89C93d8e21e10Dce93929',
    singleLimitStrategy: '0x7A732A846AfF4BCa49F4F77eEA7a851BB35bC82e',
    whiteListStrategy: '0x9c85C5560E830F60BEE57E4109b99307d44488c8',
    controller: '0x66622e2C1b991983e88132da19b2C31f71009035',
    fromBlock: 9385210,
  },
  // {
  //   chainId: 43113,
  //   guardian: "0xb6619813C4B701a31C303De70256802E97f0f6Fa",
  //   singleLimitStrategy: "0x8e221a275E05a4374c5bF48Cf0368439AC8143d8",
  //   whiteListStrategy: "0x8C95F752eb59acecB0B72B569d8D102e18035AC8",
  //   controller: "0xF601afcc4A2a1f991bf46AE37Fa87C9732aBbF84",
  //   fromBlock: 4671712,
  // },
  // {
  //   chainId: 4002,
  //   guardian: "0x13C0921A118464bB5BA4E8552e87118cBf1b37d9",
  //   singleLimitStrategy: "0x29C2541d25091B565c6999f20549EB2c75b7c269",
  //   whiteListStrategy: "0xd8b83d5Ad4102dEf6255643e8AB2BA5410D4d0f8",
  //   controller: "0xBEDba51A30CD4c8d708E038943dE0b3f0A089776",
  //   fromBlock: 6366152,
  // },
  {
    chainId: 250,
    guardian: '0xb9131977954Cf86e33F89C93d8e21e10Dce93929',
    singleLimitStrategy: '0x7A732A846AfF4BCa49F4F77eEA7a851BB35bC82e',
    whiteListStrategy: '0x9c85C5560E830F60BEE57E4109b99307d44488c8',
    controller: '0xDBB5125CEEaf7233768c84A5dF570AeECF0b4634',
    fromBlock: 25629347,
  },
];
