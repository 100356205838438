import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

// WEB3
import { useWeb3 } from './Context/';

// CONTROLLER
import { Web3Controller } from './Controller';

// STATE
import { useGlobalState } from './index';

// CONFIG
import { NetworkConfig, Token, tokens } from './config';
import { Chain, networks } from './config/networks';

// COMPONENTS
import AddressProtection from './AddressProtection';
import AddressSingleLimit from './AddressSingleLimit';
import AddressWhiteList from './AddressWhitelist';
import Error from './Error';
import Footer from './shared/Footer';
import Header from './shared/Header';
import Home from './Home';
import NotFound from './NotFound';
import ProtectionAdmin from './ProtectionAdmin';
import ProtectedAddresses from './ProtectedAddresses';
import ProtectionType from './ProtectionType';
import SelectToken from './SelectToken';
import SingleLimit from './SingleLimit';
import Whitelist from './Whitelist';

// STYLES
import './App.css';

// UTILS
import { getActiveNetworkConfig } from './utils/active-network-config';
import useSyncer  from './hooks/useSyncer'

// CONSTANTS
export const NETWORK_NOT_FOUND = 'Sorry, this network is not supported yet!';

const App = () => {
  const location = useLocation();
  useSyncer();

  const [state, dispatch] = useGlobalState();
  const { networkId, currentAddress, web3 } = useWeb3();
  const getActiveNetwork = (chainId: number): Chain | undefined => {
    return networks.find((network: Chain) => network.id === chainId);
  };

  const getSelectedToken = (): Token | undefined => (localStorage.getItem('selectedTokenAddress') ? tokens.find(token => token.address === localStorage.getItem('selectedTokenAddress')) : undefined);

  const setController = (networkConfig: NetworkConfig, account: string): void => {
    if (networkConfig)
      dispatch({
        controller: Web3Controller(networkConfig as NetworkConfig, account),
      });
  };

  useEffect(() => {
    if (web3) {
      const activeNetwork = getActiveNetwork(networkId as number);

      dispatch({
        activeNetwork,
        account: currentAddress,
        selectedToken: getSelectedToken(),
      });
    }
  }, [web3, currentAddress, networkId]);

  useEffect(() => {
    if (state.activeNetwork && state.account) {
      const account = state.account;
      const networkConfig = getActiveNetworkConfig(state.activeNetwork.id);
      if (networkConfig) setController(networkConfig, account);
      dispatch({
        account,
        networkConfig,
        selectedToken: getSelectedToken(),
        protectionType: networkConfig?.whiteListStrategy,
      });
    }
  }, [state.activeNetwork, state.account]);

  // Google Analytics
  useEffect(() => {
    if ((window as any)._env_?.REACT_APP_GA_TAG) {
      ReactGA.initialize((window as any)._env_.REACT_APP_GA_TAG);
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location]);

  return (
    <>
      <ToastContainer />
      <div className='App'>
        <Router>
          <div>
          {/* <p>{value}</p>
          <p>{status}</p> */}
          </div>
          <Header />
          <div className='content-container'>
            <Switch>
              <Route path={'/'} exact={true} component={Home} />
              <Route path={'/protection-admin'} exact={true} component={ProtectionAdmin} />
              <Route path={'/select-token'} exact={true} component={SelectToken} />
              <Route path={'/address-protection'} exact={true} component={AddressProtection} />
              <Route path={'/protection-type'} exact={true} component={ProtectionType} />
              <Route path={'/single-limit'} exact={true} component={SingleLimit} />
              <Route path={'/single-limit/:address'} exact={true} component={AddressSingleLimit} />
              <Route path={'/single-limit/:address/edit'} exact={true} component={() => <SingleLimit edit />} />
              <Route path={'/whitelist'} exact={true} component={Whitelist} />
              <Route path={'/whitelist/:address'} exact={true} component={AddressWhiteList} />
              <Route path={'/whitelist/:address/edit'} exact={true} component={() => <Whitelist edit />} />
              <Route path={'/protected-addresses'} exact={true} component={ProtectedAddresses} />
              <Route path={'/error'} exact={true} component={Error} />
              <Route component={NotFound} />
            </Switch>
          </div>
        </Router>
        <Footer />
      </div>
    </>
  );
};

export default App;
