import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import axios from 'axios';

// NETWORKS
import { Chain, networks } from '../config/networks';

// STATE
import { useGlobalState } from '../index';

// ANTD
import { Alert, Form, Input, Modal, Table } from 'antd';
import Button from 'antd/lib/button';
import { RowSelectionType } from 'antd/lib/table/interface';
import LeftOutlined from '@ant-design/icons/lib/icons/LeftOutlined';
import { Loading3QuartersOutlined } from '@ant-design/icons';

// COMPONENTS
import ActiveChain from '../shared/ActiveChain';
import ActiveAdmin from '../shared/ActiveAdmin';
import ActiveToken from '../shared/ActiveToken';

// UTILS
import { copyText } from '../utils/copy';
import { notFound } from '../utils/not-found/not-found';

// STYLES
import './styles.scss';

// ICONS
import add from '../assets/images/add.svg';
import expand from '../assets/images/expand.svg';
import removeIcon from '../assets/images/remove.svg';
import removeActiveIcon from '../assets/images/remove-active.svg';
import search from '../assets/images/search.svg';

// INTERFACES
import { Controller } from '../Controller/interfaces/controller.interface';
import { DataType } from './interfaces/address-whitelist.interface';

// CONSTANTS
export const PROTECTED_ADDRESSES_ERRROR = 'It seems there was an error getting your protected addresses';

export const WHITELIST_ADDRESS_SYNCING = 'Your new whitelist could take a couple of minutes to update, we are syncing the blocks';

const AddressWhiteList = () => {
  const history = useHistory();
  const { address } = useParams<{ address: string }>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRemoveAddressModalVisible, setIsRemoveAddressModalVisible] = useState(false);
  const [dataSource, setDataSource] = useState<{ key: number; address: string }[]>([]);

  const [state, dispatch] = useGlobalState();
  const [finalAddresswhitelistAddresses, setFinalAddresswhitelistAddresses] = useState<{ key: number; address: string }[]>([]);
  const [loading, setLoadingState] = useState(false);
  const [removeLoading, setRemoveLoadingState] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectionType] = useState<RowSelectionType>('checkbox');
  const [addressToKeep, setAddressToKeepState] = useState<string[]>([]);
  const [addressToRemove, setAddressToRemove] = useState<string[]>([]);
  const Controller = state.controller as Controller;

  const notFoundMsg = 'Whitelist is empty';

  const [form] = Form.useForm();

  const columns = [
    {
      title: 'Addresses',
      dataIndex: 'address',
      render: (address: string) => (
        <div className='address-field'>
          {address}
          {state?.account !== state?.adminAddress ? null : <img src={removeIcon} onClick={() => removeAddress(address)} />}
        </div>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedRowKeys(selectedRowKeys);
      const addresses = selectedRows.map(selectedRow => selectedRow.address);

      const addressToKeep: string[] = [];
      finalAddresswhitelistAddresses.forEach((finalAddresswhitelistAddress: { key: number; address: string }) => {
        const result = selectedRows.find(selectedRow => selectedRow.address === finalAddresswhitelistAddress.address);
        if (!result) addressToKeep.push(finalAddresswhitelistAddress.address);
      });
      setAddressToKeepState(addressToKeep);
      setAddressToRemove(addresses);
      form.setFieldsValue({ addresses });
    },
  };

  const getWhitelistAddresses = async (token: string, protectedAddress: string): Promise<void> => {
    setLoadingState(true);

    const whitelistAddresses = await axios({
      url: networks.find((network: Chain) => network.id === parseInt(window.ethereum.chainId))?.url,
      method: 'post',
      data: {
        query: `
        query WhitelistAddresses(
          $token: Bytes!
          $protectedAddress: Bytes!
        ) {
          whitelistAddressesEvents(
            where: { token: $token, protectedAddress: $protectedAddress }
          ) {
            id
            protectedAddress
            state
            token
            whitelist
          }
        }`,
        variables: {
          token,
          protectedAddress,
        },
      },
    });

    const finalWhitelistAddresses: { key: number; address: string }[] = whitelistAddresses.data.data.whitelistAddressesEvents[0]?.whitelist
      ? (whitelistAddresses.data?.data.whitelistAddressesEvents[0].whitelist).map((address: string, key: number) => {
          return {
            key,
            address,
          };
        })
      : [];

    setFinalAddresswhitelistAddresses(finalWhitelistAddresses);
    setDataSource(finalWhitelistAddresses);
    setLoadingState(false);
  };

  const removeAddress = (seletedAddress: string): void => {
    form.setFieldsValue({ addresses: [seletedAddress] });

    const addressToKeep: string[] = [];
    finalAddresswhitelistAddresses.forEach((finalAddresswhitelistAddress: { key: number; address: string }) => {
      if (seletedAddress !== finalAddresswhitelistAddress.address) addressToKeep.push(finalAddresswhitelistAddress.address);
    });

    const addressToRemove: string[] = [seletedAddress];
    setAddressToRemove(addressToRemove);
    setAddressToKeepState(addressToKeep);
    setIsRemoveAddressModalVisible(true);
  };

  const handleCancel = (): void => {
    setIsModalVisible(false);
  };

  const handleRemoveAddressCancel = (): void => {
    setIsRemoveAddressModalVisible(false);
    setSelectedRowKeys([]);
    form.setFieldsValue({ addresses: [] });
  };

  const searchAddress = (e: any): void => {
    const { value } = e.target;

    const lowercaseValue: string = value.toLowerCase();

    if (lowercaseValue === '') {
      getWhitelistAddresses(state.selectedToken?.address, address ? address : state.addressToProtect);
    } else {
      const filterDataSource = finalAddresswhitelistAddresses.filter((dataRow: { key: number; address: string }) => dataRow.address.toLowerCase().includes(lowercaseValue));

      setDataSource(filterDataSource);
    }
  };

  const navigateTo = (address: string): void => {
    history.push(address);
  };

  const removeWhiteListProtection = async (): Promise<void> => {
    setRemoveLoadingState(true);
    try {
      const removeWhitelistAddressResponse = await Controller.removeProtectedAddresses(state.selectedToken?.address, address);

      localStorage.setItem('lastInteractionBlock', removeWhitelistAddressResponse.blockNumber.toString());

      dispatch({ synced: false });

      setRemoveLoadingState(false);
      setIsModalVisible(false);

      history.push('/protected-addresses');
    } catch (error: any) {
      if (error?.code === 4001) {
        setRemoveLoadingState(false);
      } else {
        setRemoveLoadingState(false);
        history.push('/Error', {
          title: 'Oops... Something went wrong.',
          message: 'There was an error removing your Whitelist protection',
          address: address ? `/whitelist/${address}` : `/whitelist/${address}`,
        });
      }
    }
  };

  const removeWhiteListAddress = async (): Promise<void> => {
    setRemoveLoadingState(true);
    try {
      const setProtectedAddressResponse = await Controller.setWhitelistState(state.selectedToken.address, address || state.addressToProtect, addressToRemove, false);

      localStorage.setItem('lastInteractionBlock', setProtectedAddressResponse.blockNumber.toString());

      dispatch({ synced: false });

      setSelectedRowKeys([]);

      getWhitelistAddresses(state.selectedToken?.address, address ? address : state.addressToProtect);

      setRemoveLoadingState(false);
      setIsRemoveAddressModalVisible(false);
    } catch (error: any) {
      if (error?.code === 4001) {
        setRemoveLoadingState(false);
      } else {
        setRemoveLoadingState(false);
        history.push('/Error', {
          title: 'Oops... Something went wrong.',
          message: 'There was an error deleting your whitelist addresses.',
          address: address ? `/whitelist/${address}` : `/whitelist/${address}`,
        });
      }
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      addresses: [],
    });
  }, []);

  useEffect(() => {
    (address || state.addressToProtect) && state.selectedToken?.address && getWhitelistAddresses(state.selectedToken?.address, address ? address : state.addressToProtect);
  }, [state.addressToProtect, state.selectedToken?.address, state.synced]);

  return (
    <>
      <Modal className='antd-modal address-whitelist-modal' title='Are you sure you want to remove this protected address?' width={1000} visible={isModalVisible} onCancel={handleCancel} footer={null}>
        <p>After you remove this address from the list here, it will no longer be protected.</p>
        <div className='actions-container'>
          <Button size='large' type='primary' onClick={() => removeWhiteListProtection()} loading={removeLoading}>
            Remove protected address
          </Button>
          <Button className='cancel' size='large' type='primary' onClick={() => handleCancel()}>
            Cancel
          </Button>
        </div>
      </Modal>
      <Modal className='antd-modal address-whitelist-modal' title='Remove protection from these addresses?' width={1000} visible={isRemoveAddressModalVisible} onCancel={handleRemoveAddressCancel} footer={null}>
        <p>The following address(es) will no longer be part of whitelist.</p>
        <div className='remove-whitelist-addresses-list-container'>
          {form.getFieldValue('addresses')?.map((whitelistAddress: string, i: number) => {
            return (
              <div className='whitelist-confirm-address-item' key={i}>
                <span className='title'>Address {i + 1}</span>
                <span className='value'>{whitelistAddress}</span>
              </div>
            );
          })}
        </div>
        <div className='actions-container'>
          <Button size='large' type='primary' onClick={() => removeWhiteListAddress()} loading={removeLoading}>
            Remove addresses
          </Button>
          <Button className='cancel' size='large' type='primary' onClick={() => handleRemoveAddressCancel()}>
            Cancel
          </Button>
        </div>
      </Modal>
      <div className='address-whitelist-container'>
        <Form form={form}>
          <div className={`content-box ${state?.account !== state?.adminAddress ? 'disabled' : null}`}>
            <h3>
              <LeftOutlined className='go-back' onClick={() => navigateTo('/protected-addresses')} />
              <span className='title' onClick={() => copyText(address)}>
                {address} <img src={expand} />
              </span>
            </h3>
            <p>Whitelist</p>
            <div className='active-state-container'>
              <ActiveChain />
              <ActiveToken />
              <ActiveAdmin />
            </div>
            <div className='actions-container'>
              <Button className={`whitelist-btn ${state?.account !== state?.adminAddress ? 'disabled' : null}`} size='large' onClick={() => navigateTo(`/whitelist/${address}/edit`)} disabled={state?.account !== state?.adminAddress}>
                <img src={add} />
                Add New Address to Whitelist
              </Button>
              <Form.Item className='remove-protection-form-item' shouldUpdate>
                <Button className={`remove-protection-btn ${state?.account !== state?.adminAddress ? 'disabled' : null}`} size='large' onClick={() => setIsModalVisible(true)} disabled={state?.account !== state?.adminAddress}>
                  <img src={removeIcon} className='remove-icon' />
                  <img src={removeActiveIcon} className='remove-active' />
                  Remove Protection
                </Button>
              </Form.Item>
            </div>
            {!state.synced ? <Alert className='info-message' description={WHITELIST_ADDRESS_SYNCING} type='info' showIcon /> : null}
            <div className='search-container'>
              <Input className='search-input' placeholder='Search for addresses' onChange={searchAddress} prefix={<img src={search} />} />
            </div>
            <Table
              className='test'
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              scroll={{ y: 315 }}
              loading={{
                indicator: <Loading3QuartersOutlined className='table-loader' spin />,
                spinning: loading,
              }}
              locale={{
                emptyText: !loading ? notFound(notFoundMsg) : <></>,
              }}
            ></Table>
            <Form.Item shouldUpdate>
              {dataSource && dataSource.length > 0
                ? () =>
                    form.getFieldValue('addresses')?.length > 0 ? (
                      <Button className='remove-selected-btn' size='large' onClick={() => setIsRemoveAddressModalVisible(true)}>
                        Remove Selected Addresses
                      </Button>
                    ) : null
                : null}
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddressWhiteList;
