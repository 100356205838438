import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { Chain, networks } from './config/networks';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// GRAPHQL
import { InMemoryCache } from '@apollo/client/cache/inmemory/inMemoryCache';
import { ApolloClient } from '@apollo/client/core/ApolloClient';
import { ApolloProvider } from '@apollo/client/react/context/ApolloProvider';

// SENTRY
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Integration } from '@sentry/react/node_modules/@sentry/types';

// WEB3
import Web3 from 'web3';
import { Web3Container } from './Context';

// COMPONENTS
import App from './App';

// INTERFACES
import { NetworkConfig, Token } from './config';
import { Controller } from './Controller/interfaces/controller.interface';

// STYLES
import './index.scss';
import './antd.scss';

const cache: InMemoryCache = new InMemoryCache();

const client = new ApolloClient({
  uri: networks.find((network: Chain) => network.id === parseInt(window?.ethereum?.chainId))?.url,
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
});

if ((window as any)._env_?.REACT_APP_ENVIRONMENT !== 'DEVELOPMENT') {
  Sentry.init({
    dsn: (window as any)._env_?.REACT_APP_SENTRY,
    integrations: [new Integrations.BrowserTracing() as Integration],
    tracesSampleRate: 1.0,
    environment: (window as any)._env_?.REACT_APP_ENVIRONMENT,
  });
}

declare global {
  interface Window {
    ethereum: any;
    web3: Web3;
  }
}

export interface GlobalState {
  tokenAdmin: string | undefined;
  account: string | undefined;
  selectedToken: Token | undefined;
  adminAddress: string | undefined;
  protectionType: string | undefined;
  addressToProtect: string | undefined;
  networkConfig: NetworkConfig | undefined;
  controller: Controller | undefined;
  activeNetwork: Chain | undefined;
  isFirstTime: boolean;
  synced: boolean;
}

const defaultGlobalState: GlobalState = {
  tokenAdmin: undefined,
  account: undefined,
  selectedToken: undefined,
  adminAddress: undefined,
  protectionType: undefined,
  addressToProtect: undefined,
  networkConfig: undefined,
  controller: undefined,
  activeNetwork: undefined,
  isFirstTime: true,
  synced: true,
};

const GlobalStateContext = React.createContext(defaultGlobalState);
const DispatchStateContext = React.createContext<any>(undefined);

type GlobalProviderProps = { children: React.ReactNode };

const GlobalStateProvider = ({ children }: GlobalProviderProps) => {
  const [state, dispatch] = React.useReducer((state: any, newValue: any) => ({ ...state, ...newValue }), defaultGlobalState);

  return (
    <GlobalStateContext.Provider value={state}>
      <DispatchStateContext.Provider value={dispatch}>{children}</DispatchStateContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => [React.useContext(GlobalStateContext), React.useContext(DispatchStateContext)];

ReactDOM.render(
  <GlobalStateProvider>
    <Web3Container>
      <ApolloProvider client={client}>
        <Router>
          <App />
        </Router>
      </ApolloProvider>
    </Web3Container>
  </GlobalStateProvider>,
  document.getElementById('root'),
);

reportWebVitals();
