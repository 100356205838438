import ethereumIcon from "../assets/images/eth.svg";
import binanceIcon from "../assets/images/bnb.svg";
import polygonIcon from "../assets/images/polygon.svg";
import harmonyIcon from "../assets/images/harmony.svg";
import avalancheIcon from "../assets/images/avalanche.svg";
import fantomIcon from "../assets/images/fantom.svg";

export interface Chain {
  id: number;
  name: string;
  icon: string;
  url?: string;
  graphName?: string;
  graphId?: string;
}

export const eth: Chain = {
  id: 1,
  name: "Ethereum Network",
  icon: ethereumIcon,
  url: (window as any)._env_?.REACT_APP_MAINNET_NETWORK,
  graphId: (window as any)._env_?.REACT_APP_MAINNET_NETWORK_ID,
  graphName: (window as any)._env_?.REACT_APP_MAINNET_NETWORK_NAME,
};

export const rpt: Chain = {
  id: 3,
  name: "Ropsten Network",
  icon: ethereumIcon,
  url: (window as any)._env_?.REACT_APP_ROPSTEN_NETWORK,
  graphId: (window as any)._env_?.REACT_APP_ROPSTEN_NETWORK_ID,
  graphName: (window as any)._env_?.REACT_APP_ROPSTEN_NETWORK_NAME,
};

export const rkb: Chain = {
  id: 4,
  name: "Rinkeby Network",
  icon: ethereumIcon,
  url: (window as any)._env_?.REACT_APP_RINKEBY_NETWORK,
  graphId: (window as any)._env_?.REACT_APP_RINKEBY_NETWORK_ID,
  graphName: (window as any)._env_?.REACT_APP_RINKEBY_NETWORK_NAME,
};

export const bsc: Chain = {
  id: 56,
  name: "BSC Network",
  icon: binanceIcon,
  url: (window as any)._env_?.REACT_APP_BSC_NETWORK,
  graphId: (window as any)._env_?.REACT_APP_BSC_NETWORK_ID,
  graphName: (window as any)._env_?.REACT_APP_BSC_NETWORK_NAME,
};

export const bscTestnet: Chain = {
  id: 97,
  name: "BSC Testnet",
  icon: binanceIcon,
};

export const mumbai: Chain = {
  id: 80001,
  name: "Mumbai Network",
  icon: polygonIcon,
  url: (window as any)._env_?.REACT_APP_MUMBAI_NETWORK,
  graphId: (window as any)._env_?.REACT_APP_MUMBAI_NETWORK_ID,
  graphName: (window as any)._env_?.REACT_APP_MUMBAI_NETWORK_NAME,
};

export const plg: Chain = {
  id: 137,
  name: "Polygon Network",
  icon: polygonIcon,
  url: (window as any)._env_?.REACT_APP_MATIC_NETWORK,
  graphId: (window as any)._env_?.REACT_APP_MATIC_NETWORK_ID,
  graphName: (window as any)._env_?.REACT_APP_MATIC_NETWORK_NAME,
};

export const harmonyTestnet: Chain = {
  id: 1666700000,
  name: "Harmony Testnet (Shard 0)",
  icon: harmonyIcon,
};

export const harmony: Chain = {
  id: 1666600000,
  name: "Harmony Mainnet Shard 0",
  icon: harmonyIcon,
};

export const avalanche: Chain = {
  id: 43114,
  name: "Avalanche Network",
  icon: avalancheIcon,
  url: (window as any)._env_?.REACT_APP_AVALANCHE_NETWORK,
  graphId: (window as any)._env_?.REACT_APP_AVALANCHE_NETWORK_ID,
  graphName: (window as any)._env_?.REACT_APP_AVALANCHE_NETWORK_NAME,
};

export const avalancheTestnet: Chain = {
  id: 43113,
  name: "Avalanche Fuji Testnet",
  icon: avalancheIcon,
};

export const fantomTestnet: Chain = {
  id: 4002,
  name: "Fantom Testnet",
  icon: fantomIcon,
};

export const fantomOpera: Chain = {
  id: 250,
  name: "Fantom Opera",
  icon: fantomIcon,
  url: (window as any)._env_?.REACT_APP_FANTOM_NETWORK,
  graphId: (window as any)._env_?.REACT_APP_FANTOM_NETWORK_ID,
  graphName: (window as any)._env_?.REACT_APP_FANTOM_NETWORK_NAME,
};

export const networks = [
  eth,
  rpt,
  bsc,
  rkb,
  //bscTestnet,
  plg,
  mumbai,
  //harmony,
  //harmonyTestnet,
  avalanche,
  //avalancheTestnet,
  //fantomTestnet,
  fantomOpera,
];
