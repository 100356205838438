import React, { useEffect, useState } from "react";

// STATE
import { useGlobalState } from "../../index";

// IMAGES
import addressIcon from "../../assets/images/address-icon.png";

// STYLES
import "./styles.scss";

// UTILS
import { copyText } from "../../utils/copy";
import { formatAddress } from "../../utils/format-address";

const TokenAdmin = () => {
  const [state, dispatch] = useGlobalState();
  const [loading, setLoadingState] = useState(false);

  const isProtectionAdminSaved = async (): Promise<string | void> => {
    setLoadingState(true);
    try {
      const tokenAdmin = await state?.controller.tokenAdmin(
        state.selectedToken?.address
      );
      dispatch({ tokenAdmin });
      setLoadingState(false);
    } catch (error: any) {
      setLoadingState(false);
    }
  };

  useEffect(() => {
    state?.controller &&
      state.selectedToken?.address &&
      isProtectionAdminSaved();
  }, [state.selectedToken?.address, state?.controller]);

  return (
    <div
      className={`active-item-container ${
        state.networkConfig ? null : "disabled"
      }`}
    >
      <span className="active-item-icon">
        <img src={addressIcon} />
      </span>
      <span className="active-item-icon">
        Token Admin:
        {loading ? (
          <span className="item-name address">Loading ...</span>
        ) : (
          <span
            className="item-name address"
            onClick={() => copyText(state?.tokenAdmin)}
          >
            {state?.tokenAdmin
              ? formatAddress(state?.tokenAdmin as string)
              : "N/A"}
          </span>
        )}
      </span>
    </div>
  );
};

export default TokenAdmin;
