import React, { useEffect, useState } from "react";

// ANTD
import { Input } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';

// STYLES
import "./styles.scss";

const { TextArea } = Input;

const WhiteListTextArea = ({
    addresses,
    loading,
    setWhitelistAddressesState
}: {
    addresses: string[],
    loading: boolean,
    setWhitelistAddressesState: React.Dispatch<React.SetStateAction<string[]>>
}) => {

    const [rows, setRowsState] = useState<any[]>([]);
    const [scrollTop, setScrollTopState] = useState<number>(0);
    const [textAreaValue, setTextAreaValue] = useState<string>();

    const scrollListener = document.getElementsByClassName("whitelist-textarea-content")[0];
    scrollListener && scrollListener.addEventListener('scroll', (e: any) => {
        setScrollTopState(e.target.scrollTop);
    });

    function handleChange(e: any) {
        setTextAreaValue(e.target.value);
        setRowsState(e.target.value.split("\n"));
        setWhitelistAddressesState(e.target.value.split("\n"));
    }

    const setTextArea = (): void => {
        let textareaValue: string = '';
        addresses.forEach((address: string, i: number) => {
            if (i === addresses.length - 1) {
                textareaValue += `${address}`;
            } else {
                textareaValue += `${address}\n`;
            }
        });
        setTextAreaValue(textareaValue);
        setRowsState(textareaValue.split("\n"));
    }

    useEffect((): void => {
        setTextArea();
    }, [addresses]);

    return (
        <div className="whitelist-addresses-container">
            {
                loading ? (
                    <div className="text-area-loading-container">
                        <Loading3QuartersOutlined spin />
                    </div>
                ) : (
                    <>
                        <div className="textarea-indexes-container">
                            <div className="textarea-indexes-content" style={{ bottom: scrollTop }}>
                                {rows.map((row: string, i: number) => <span key={i}>{i + 1}</span>)}
                            </div>
                        </div>
                        <TextArea onChange={handleChange} className="whitelist-textarea-content" value={textAreaValue} />
                    </>
                )
            }
        </div>
    )
};

export default WhiteListTextArea;
