import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

// STATE
import { useGlobalState } from '../index';

// ANTD
import { Form, Input } from 'antd';
import Button from 'antd/lib/button';

// COMPONENTS
import ActiveChain from '../shared/ActiveChain';
import ActiveProtectionType from '../shared/ActiveProtectionType';
import ActiveToken from '../shared/ActiveToken';

// STYLES
import './styles.scss';

// INTERFACES
import { Controller } from '../Controller/interfaces/controller.interface';

// CONSTANTS
import { IS_ADDRESS, ADDRESS_IS_VERIFIED, ADDRESS_PROTECTED_ERROR, PROCTECTED_ADDRESS_EXIST } from './constants';

const AddressProtection = () => {
  const history = useHistory();
  const [addressProtectionLoading, setAddressProtectionLoading] = useState(false);
  const [state, dispatch] = useGlobalState();
  const [loading, setLoadingState] = useState(false);

  // CONTROLLER
  const Controller = state.controller as Controller;

  const [form] = Form.useForm();

  // ETH ACCOUNT REGEX
  const isAddressRegExp = new RegExp(/^0x[a-fA-F0-9]{40}$/);

  const protectAddress = async (protectionType: string, tokenAddress: string, singleLimitStrategy: string, whiteListStrategy: string): Promise<void> => {
    setLoadingState(true);
    const addressToProtect = form.getFieldValue('addressToProtect');
    dispatch({ addressToProtect });
    try {
      const isAddressVerifiedResponse = await Controller.isAddressVerified(tokenAddress, addressToProtect);
      setLoadingState(false);
      if (isAddressVerifiedResponse) {
        if (protectionType === singleLimitStrategy) {
          history.push(`/single-limit`);
        } else if (protectionType === whiteListStrategy) {
          history.push(`/whitelist`);
        }
      } else {
        setLoadingState(false);
      }
    } catch (error: any) {
      if (error?.code === 4001) {
        setLoadingState(false);
      } else {
        setLoadingState(false);
        history.push('/Error', {
          title: 'Oops... Something went wrong.',
          message: 'There was an error verifying your address',
          address: '/address-protection',
        });
      }
    }
  };

  const isAddress = (address: string): boolean => {
    const isAddressTest = isAddressRegExp.test(address);
    if (isAddressTest && address.length) {
      return true;
    } else {
      return false;
    }
  };

  const isAddressVerified = async (address: string, tokenAddress: string): Promise<boolean> => {
    try {
      const isAddressVerifiedResponse = await Controller.isAddressVerified(tokenAddress, address);
      return isAddressVerifiedResponse;
    } catch (error) {
      setAddressProtectionLoading(false);
      throw new Error(ADDRESS_PROTECTED_ERROR);
    }
  };

  const isAddressProtected = async (token: string, protectedAddress: string): Promise<void> => {
    try {
      const isAddressProtected = await Controller.isAddressProtected(token, protectedAddress);
      if (isAddressProtected) toast.warn(PROCTECTED_ADDRESS_EXIST);
    } catch (error) {
      setAddressProtectionLoading(false);
      throw new Error(ADDRESS_PROTECTED_ERROR);
    }
  };

  async function handleChange(rule: any, address: string, tokenAddress: string): Promise<any> {
    setAddressProtectionLoading(true);
    form.setFieldsValue({ addressToProtect: address });
    const isAddressTest = isAddress(address);
    if (isAddressTest) {
      const isAddressVerifiedResponse = await isAddressVerified(address, tokenAddress);
      if (isAddressVerifiedResponse) {
        await isAddressProtected(tokenAddress, address);
        form.setFieldsValue({ addressToProtect: address });
        setAddressProtectionLoading(false);
      } else {
        setAddressProtectionLoading(false);
        throw new Error(ADDRESS_IS_VERIFIED);
      }
    } else if (!isAddressTest) {
      setAddressProtectionLoading(false);
      throw new Error(IS_ADDRESS);
    }
  }

  return (
    <div className='address-protection-container'>
      <Form form={form} layout='vertical'>
        <div className='content-box'>
          <h3>Enter Address to Protect</h3>
          <p>Enter the wallet address you want to protect.</p>
          <div className='active-state-container'>
            <ActiveChain />
            <ActiveToken />
            <ActiveProtectionType />
          </div>
          <Form.Item
            label='Address'
            name='addressToProtect'
            rules={[
              {
                validator: async (rule, value) => handleChange(rule, value, state.selectedToken.address),
              },
            ]}
            extra='Please note, that address has to be verified by lossless.'
          >
            <Input size='large' placeholder='Address to protect' disabled={addressProtectionLoading}></Input>
          </Form.Item>
          <div className='br-line' />
          <Form.Item shouldUpdate>
            {() => (
              <Button
                size='large'
                type='primary'
                onClick={() => protectAddress(state.protectionType, state.selectedToken.address, state.networkConfig.singleLimitStrategy, state.networkConfig.whiteListStrategy)}
                disabled={!form.getFieldValue('addressToProtect') || form.getFieldsError().filter(({ errors }) => errors.length).length > 0 || !form.isFieldsTouched() || addressProtectionLoading}
                loading={loading}
              >
                Next
              </Button>
            )}
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default AddressProtection;
