import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';

// NETWORKS
import { Chain, networks } from '../config/networks';

// GRAPQHL
import { ProtectedAddressSetEvent } from '../graphql/generated/graphql';

// STATE
import { useGlobalState } from '../index';

// ANTD
import { Alert, Table } from 'antd';
import Button from 'antd/lib/button';

// COMPONENTS
import ActiveAdmin from '../shared/ActiveAdmin';
import ActiveChain from '../shared/ActiveChain';
import ActiveToken from '../shared/ActiveToken';
import TokenAdmin from '../shared/TokenAdmin';

// STYLES
import './styles.scss';

// UTILS
import { notFound } from '../utils/not-found/not-found';

// ICONS
import add from '../assets/images/add.svg';
import Loading3QuartersOutlined from '@ant-design/icons/lib/icons/Loading3QuartersOutlined';
import next from '../assets/images/next.svg';

// INTERFACES
import { DataType } from './interfaces/protected-addresses.interface';

// CONSTANTS
export const PROTECTED_ADDRESSES_ERRROR = 'It seems there was an error getting your protected addresses';
export const PROTECTED_ADDRESSES_SYNCING = 'Your new protected addresses could take a couple of minutes to update, we are syncing the blocks';
export const NO_TOKEN = 'No token has been selected. Please select a token to continue.';
const columns = [
  {
    title: 'Addresses',
    dataIndex: 'protectedAddress',
    key: 'protectedAddress',
    render: (protectedAddress: string) => protectedAddress,
  },
  {
    title: 'Type',
    dataIndex: 'strategy',
    key: 'strategy',
    render: (strategy: string) => {
      if (strategy === WHITELIST) {
        return WHITELIST;
      } else if (strategy === SINGLE_LIMIT) {
        return SINGLE_LIMIT;
      }
    },
  },
  {
    title: '',
    dataIndex: '',
    key: 'next',
    render: () => <img src={next} className='next-btn' />,
  },
];

export const WHITELIST = 'Whitelist';
export const SINGLE_LIMIT = 'Single Limit';

const ProtectedAddresses = () => {
  const history = useHistory();
  const [state, dispatch] = useGlobalState();
  const [loading, setLoadingState] = useState(false);
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const notFoundMsg = 'No protected addresses found';

  const navigateTo = (route: string): void => {
    history.push(route);
  };

  const onRow = (rowIndex: DataType): { onClick: () => void } => {
    return {
      onClick: () => {
        const result = dataSource.find((row: DataType) => row.key === rowIndex.key);
        if (result?.strategy === WHITELIST) {
          history.push(`/whitelist/${rowIndex.protectedAddress}`);
        } else if (result?.strategy === SINGLE_LIMIT) {
          history.push(`/single-limit/${rowIndex.protectedAddress}`);
        }
      },
    };
  };

  const getStrategyDescription = (strategy: string, singleLimitAddress: string, whiteListAddress: string): string | undefined => {
    if (strategy === singleLimitAddress.toLowerCase()) {
      return SINGLE_LIMIT;
    } else if (strategy === whiteListAddress.toLowerCase()) {
      return WHITELIST;
    }
  };

  const getProtectedAddresses = async (token: string, singleLimitStrategy: string, whitelistStrategy: string): Promise<void> => {
    setLoadingState(true);

    const protectedAddresses = await axios({
      url: networks.find((network: Chain) => network.id === parseInt(window.ethereum.chainId))?.url,
      method: 'post',
      data: {
        query: `
        query ProtectedAddresses($token: Bytes!) {
          protectedAddresses(
            where: { token: $token }
          ) {
            id
            blockNumber
            protectedAddress
            strategy
            token
          }
        }`,
        variables: {
          token,
        },
      },
    });

    const formatedProtectedAddresses: DataType[] =
      protectedAddresses.data.data.protectedAddresses?.map((protectedAddress: ProtectedAddressSetEvent, i: number) => {
        return {
          key: i,
          protectedAddress: protectedAddress.protectedAddress,
          strategy: getStrategyDescription(protectedAddress.strategy, singleLimitStrategy, whitelistStrategy) as string,
        };
      }) || [];

    setDataSource(formatedProtectedAddresses);
    setLoadingState(false);
  };

  useEffect(() => {
    state.selectedToken?.address && state.networkConfig && getProtectedAddresses(state.selectedToken.address, state.networkConfig.singleLimitStrategy, state.networkConfig.whiteListStrategy);
  }, [state.selectedToken?.address, state.networkConfig, state.synced]);

  return (
    <div className='protected-addresses-container'>
      <div className='content-box'>
        <h3>
          Protected Addresses
          <Button size='large' className='protection-admin-btn' onClick={() => navigateTo('/protection-admin')}>
            Change Protection Admin
          </Button>
        </h3>
        <p>List of your protected addresses.</p>
        <div className='active-state-container'>
          <ActiveChain />
          <ActiveToken />
          <TokenAdmin />
          <ActiveAdmin />
        </div>
        {!state.synced ? <Alert className='info-message' description={PROTECTED_ADDRESSES_SYNCING} type='info' showIcon /> : null}
        {!state.selectedToken ? <Alert className='info-message' description={NO_TOKEN} type='info' showIcon /> : null}
        <div className='protected-addresses-list-container'>
          <Table
            onRow={onRow}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            loading={{
              indicator: <Loading3QuartersOutlined className='table-loader' spin />,
              spinning: loading,
            }}
            locale={{
              emptyText: !loading ? notFound(notFoundMsg) : <></>,
            }}
          />
        </div>
        <Button size='large' className={`add-new-btn ${state.account !== state?.adminAddress ? 'disabled' : null}`} onClick={() => navigateTo('/protection-type')} disabled={state.account !== state?.adminAddress || !state.selectedToken}>
          <img src={add} />
          Add new address
        </Button>
      </div>
    </div>
  );
};

export default ProtectedAddresses;
