import { ethers } from 'ethers';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';

// PROTECTION ADMIN ABI
const abi = require('../../abis/LiquidityProtectionSingleLimitStrategy.json');

export const getSingleLimitContract = (provider: Web3, address: string): Contract => {
    return new provider.eth.Contract(abi, address);
}

export const getGnosisSingleLimitContract = (provider: Web3, contractAddress: string): Contract => {
    return new provider.eth.Contract(abi, contractAddress);
}