import React, { useEffect } from "react";
import { useHistory } from "react-router";

// STATE
import { useGlobalState } from "../index";

// ANTD
import Button from "antd/lib/button";

// COMPONENTS
import ActiveChain from "../shared/ActiveChain";
import ActiveToken from "../shared/ActiveToken";

// STYLES
import "./styles.scss";

// ICONS
import whitelist from "../assets/images/white-list.png";
import singlelimit from "../assets/images/single-limit.png";

const ProtectionType = () => {
  const history = useHistory();
  const [state, dispatch] = useGlobalState();

  const navigateTo = (): void => {
    history.push("address-protection");
  };

  useEffect(() => {
    dispatch({
      protectionType: state?.protectionType
        ? state.protectionType
        : state.networkConfig?.whiteListStrategy,
    });
  }, []);

  return (
    <div className="protection-type-container">
      <div className="content-box">
        <h3>Select Protection Type</h3>
        <p>Please enter some basic information about protection types.</p>
        <div className="active-state-container">
          <ActiveChain />
          <ActiveToken />
        </div>
        <div className="actions-container">
          <div
            className={`action-item ${
              state.protectionType === state.networkConfig?.whiteListStrategy
                ? "active"
                : ""
            }`}
            onClick={() =>
              dispatch({
                protectionType: state.networkConfig?.whiteListStrategy,
              })
            }
          >
            <img src={whitelist} />
            <h3>Whitelist</h3>
            <p>
              Simple and gas efficient - perfect for treasury wallet. Only
              whitelisted addresses can receive tokens from this wallet.
            </p>
          </div>
          <div
            className={`action-item ${
              state.protectionType === state.networkConfig?.singleLimitStrategy
                ? "active"
                : ""
            }`}
            onClick={() =>
              dispatch({
                protectionType: state.networkConfig?.singleLimitStrategy,
              })
            }
          >
            <img src={singlelimit} />
            <h3>Single Limit</h3>
            <p>
              Good for addresses interacting with retail users. Uses more gas,
              needs proper configuration to work efficiently.
            </p>
          </div>
        </div>
        <div className="br-line" />
        <div>
          <Button size="large" type="primary" onClick={() => navigateTo()}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProtectionType;
