import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { BigNumber } from 'ethers';
import moment from 'moment';

// STATE
import { useGlobalState } from '../index';

// ANTD
import { DatePicker, Form, Input, Modal } from 'antd';
import Button from 'antd/lib/button';

// COMPONENTS
import ActiveAddress from '../shared/ActiveAddress';
import ActiveChain from '../shared/ActiveChain';
import ActiveProtectionType from '../shared/ActiveProtectionType';
import ActiveToken from '../shared/ActiveToken';

// STYLES
import './styles.scss';

// ICONS
import calendar from '../assets/images/calendar.svg';

// INTERFACES
import { Controller } from '../Controller/interfaces/controller.interface';

// CONSTANTS
import { START_TIME_NOT_VALID, HOURS_NOT_VALID, MINUTES_NOT_VALID, MAX_VALUE, TOKEN_SUPPLY_REQUIRED, TOKEN_SUPPLY_MAX_NOT_VALID, TOKEN_SUPPLY_NOT_VALID } from './constants';

const SingleLimit = ({ edit }: { edit: boolean }) => {
  const history = useHistory();
  const [loading, setLoadingState] = useState(false);
  const [tokenSupply, setTokenSupplyState] = useState<string>();
  const { address } = useParams<{ address: string }>();
  const [state, dispatch] = useGlobalState();
  const [form] = Form.useForm();
  const Controller = state.controller as Controller;

  const dateFormat = 'YYYY/MM/DD HH:mm:ss';

  const [isModalVisible, setIsModalVisible] = useState(false);

  const navigateTo = (address: string): void => {
    history.push(address);
  };

  const setProtectionType = useCallback(() => {
    if (state.protectionType !== state.networkConfig?.singleLimitStrategy) dispatch({ protectionType: state.networkConfig?.singleLimitStrategy });
  }, []);

  const handleCancel = (): void => {
    setIsModalVisible(false);
  };

  const setSingleLimit = async (): Promise<void> => {
    setLoadingState(true);

    const startTime = Math.floor(form.getFieldValue('startTime').toDate().getTime() / 1000);
    const hours = form.getFieldValue('hours');
    const minutes = form.getFieldValue('minutes');
    const tokenAmount = form.getFieldValue('tokenAmount');
    const limitPeriod = BigNumber.from(hours)
      .mul(3600)
      .add(minutes * 60)
      .toString();

    const parseTokenAmount = parseFloat(tokenAmount) * 1000000;
    const bigTokenAmount = BigNumber.from(parseTokenAmount.toString())
      .mul(BigNumber.from([10]).pow(state.selectedToken.decimals))
      .div(1000000);

    try {
      const setLimitResponse = await Controller.setLimit(state.selectedToken.address, address || state.addressToProtect, limitPeriod, bigTokenAmount, startTime);

      localStorage.setItem('lastInteractionBlock', setLimitResponse.blockNumber.toString());

      dispatch({ synced: false });

      setLoadingState(false);
      history.push('/protected-addresses');
    } catch (error: any) {
      if (error?.code === 4001) {
        setLoadingState(false);
      } else {
        setLoadingState(false);
        history.push('/Error', {
          title: 'Oops... Something went wrong.',
          message: 'Looks like you did not use the admin wallet to connect or the token is not permitted to use vault protection tool.',
          address: address ? `/single-limit/${address}` : `/single-limit`,
        });
      }
    }
  };

  const setTokenAmount = (tokenAmount: string, tokenDecimals: number, fixedTo: number): string => {
    return BigNumber.from(tokenAmount).div(Math.pow(10, tokenDecimals).toString()).toNumber().toFixed(fixedTo);
  };

  const getTokenSupply = async (tokenAddress: string): Promise<void> => {
    const tokenSupply = await Controller.totalSupply(tokenAddress);
    setTokenSupplyState(tokenSupply);
  };

  const getAddressSingleLimit = async (address: string): Promise<void> => {
    setLoadingState(true);
    try {
      const limit = await Controller.getLimit(state.selectedToken.address, address);
      const bigLimit = BigNumber.from(limit.periodInSeconds);
      const bigHours = bigLimit.div(3600);
      const bigMinutes = bigLimit.mod(3600).div(60);

      form.setFieldsValue({
        startTime: moment(new Date(parseInt(limit.lastCheckpointTime) * 1000), dateFormat),
        hours: bigHours.toString(),
        minutes: bigMinutes.toString(),
        tokenAmount: setTokenAmount(limit.amountPerPeriod, state.selectedToken.decimals, 6),
      });
      setLoadingState(false);
    } catch (error: any) {
      setLoadingState(false);
      history.push('/Error', {
        title: 'Oops... Something went wrong.',
        message: 'There was an error getting your single limit configuration',
        address: address ? `/single-limit/${address}` : `/single-limit/${state.addressToProtect}`,
      });
    }
  };

  async function validateStartTime(rule: any, startTime: any): Promise<any> {
    if (new Date().getTime() < startTime.toDate().getTime()) {
      throw new Error(START_TIME_NOT_VALID);
    }
  }

  async function validateHours(rule: any, hours: string): Promise<any> {
    const parsedHours = parseInt(hours);
    let bigHours;
    let hoursLimit;
    try {
      bigHours = BigNumber.from(hours || 0);
      hoursLimit = BigNumber.from(MAX_VALUE).div(3600);
    } catch (error) {
      throw new Error(HOURS_NOT_VALID);
    }

    if ((hoursLimit && bigHours?.gt(hoursLimit)) || !hours || parsedHours < 0 || isNaN(+hours)) {
      throw new Error(HOURS_NOT_VALID);
    }
  }

  async function validateMinutes(rule: any, minutes: string): Promise<any> {
    const parsedMinutes = parseInt(minutes);
    if (parsedMinutes > 59 || !minutes || parsedMinutes < 0 || isNaN(+minutes)) {
      throw new Error(MINUTES_NOT_VALID);
    }
  }

  async function validateTokenSupply(rule: any, supply: string): Promise<any> {
    const bigTokenSupply = BigNumber.from(tokenSupply).div(Math.pow(10, state.selectedToken.decimals).toString()).toNumber();

    if (parseFloat(supply) > bigTokenSupply) {
      throw new Error(TOKEN_SUPPLY_MAX_NOT_VALID);
    } else if (parseFloat(supply) <= 0) {
      throw new Error(TOKEN_SUPPLY_NOT_VALID);
    } else if (!supply) {
      throw new Error(TOKEN_SUPPLY_REQUIRED);
    }
  }

  useEffect((): void => {
    setProtectionType();
    !edit &&
      form.setFieldsValue({
        startTime: moment(new Date(), dateFormat),
        hours: 1,
        minutes: 30,
        tokenAmount: 20000,
      });
    edit && state.selectedToken && getAddressSingleLimit(address);
    state.selectedToken && getTokenSupply(state.selectedToken.address);
    if (address === undefined && state.addressToProtect === undefined) {
      history.push('/address-protection');
    }
  }, [state.addressToProtect]);

  return (
    <>
      <Modal className='antd-modal single-limit-modal' title='Detail confirmation' visible={isModalVisible} onCancel={handleCancel} width={1000} footer={null}>
        <div className='single-limit-item'>
          <span className='title'>Start Time</span>
          <span className='value'>{form.getFieldValue('startTime')?.format(dateFormat)}</span>
        </div>
        <div className='single-limit-item'>
          <span className='title'>Limit Period</span>
          <span className='value'>{`${form.getFieldValue('hours')}h ${form.getFieldValue('minutes')}min`}</span>
        </div>
        <div className='single-limit-item'>
          <span className='title'>Amount of Tokens</span>
          <span className='value'>{form.getFieldValue('tokenAmount')}</span>
        </div>
        <div className='actions-container'>
          <Button size='large' type='primary' onClick={() => setSingleLimit()} disabled={form.getFieldsError().filter(({ errors }) => errors.length).length > 0 || !form.isFieldsTouched()} loading={loading}>
            Confirm
          </Button>
          <Button className='cancel' size='large' onClick={() => handleCancel()}>
            Cancel
          </Button>
        </div>
      </Modal>
      <div className='single-limit-container'>
        <Form form={form} layout='vertical'>
          <div className='content-box'>
            {edit ? <h3>Edit Limits</h3> : <h3>Single Limit</h3>}
            <p>
              From Start Time, token transfers from this address will be limited to Amount of Tokens every Limit Period. Please have in mind that such limits may disrupt regular activity if imposed, for example, on a trading pool during a
              period of high volatility.
            </p>
            <div className='active-state-container'>
              <ActiveChain />
              <ActiveToken />
              <ActiveProtectionType />
              <ActiveAddress address={address} />
            </div>
            <div className='actions-container'>
              <Form.Item
                label='Start Time'
                name='startTime'
                className='start-time-container'
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: async (rule, value) => validateStartTime(rule, value),
                  },
                ]}
              >
                <DatePicker showNow={false} size='large' showTime suffixIcon={<img src={calendar} />} />
              </Form.Item>
              <div className='limit-period-container'>
                <Input.Group compact>
                  <Form.Item
                    label='Limit Period'
                    name='hours'
                    rules={[
                      {
                        validator: async (rule, value) => validateHours(rule, value),
                      },
                    ]}
                  >
                    <Input style={{ width: '100%', borderRadius: '8px 0px 0px 8px' }} suffix='H' />
                  </Form.Item>
                  <Form.Item
                    label='&nbsp;'
                    name='minutes'
                    className='minutes'
                    rules={[
                      {
                        validator: async (rule, value) => validateMinutes(rule, value),
                      },
                    ]}
                  >
                    <Input style={{ width: '100%', borderRadius: '0px 8px 8px 0px' }} suffix='MIN' />
                  </Form.Item>
                </Input.Group>
              </div>
              <Form.Item
                label='Amount of Tokens'
                name='tokenAmount'
                className='tokens-amount-container'
                rules={[
                  {
                    validator: async (rule, value) => validateTokenSupply(rule, value),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className='br-line' />
            {edit ? (
              <Form.Item shouldUpdate>
                {() => (
                  <div className='single-limit-edit'>
                    <Button size='large' className='cancel-btn' type='primary' onClick={() => navigateTo(`/single-limit/${address}`)}>
                      Cancel
                    </Button>
                    <Button size='large' type='primary' onClick={() => setIsModalVisible(true)} disabled={form.getFieldsError().filter(({ errors }) => errors.length).length > 0 || !form.isFieldsTouched()}>
                      Save
                    </Button>
                  </div>
                )}
              </Form.Item>
            ) : (
              <Form.Item shouldUpdate>
                {() => (
                  <Button size='large' type='primary' onClick={() => setIsModalVisible(true)} disabled={form.getFieldsError().filter(({ errors }) => errors.length).length > 0 || !form.isFieldsTouched()}>
                    Save
                  </Button>
                )}
              </Form.Item>
            )}
          </div>
        </Form>
      </div>
    </>
  );
};

export default SingleLimit;
