import { useState, useEffect } from 'react';
import axios from 'axios';
import { useGlobalState } from '../index';
import { useWeb3 } from '../Context/';
import { id } from 'ethers/lib/utils';

export default function useSyncer(DEBUG_SYNCER = false) {
  const [state, dispatch] = useGlobalState();
  const [isSyncing, setIsSyncing] = useState(false);
  const { web3 } = useWeb3();
  const BACKEND_URL: string = (window as any)._env_.REACT_APP_BACKEND_NODE_URL;
  const SYNC_TIMER = 2000;

  const syncBlocks = async (): Promise<void> => {
    setIsSyncing(true);
    const latestInteractionBlock: any = localStorage.getItem('lastInteractionBlock');
    const subgraphLatestBlock = await getSubgraphData(state.activeNetwork?.graphName, state.activeNetwork?.graphId);
    const userCurrentBlock: any = await web3?.eth.getBlockNumber();

    const blockSyncObj = {
      blocksToSync: latestInteractionBlock ? +latestInteractionBlock : +userCurrentBlock,
      type: latestInteractionBlock ? 'INTERACTION' : 'USER',
    };

    if (DEBUG_SYNCER) {
      console.log('Last block interaction: ', +latestInteractionBlock);
      console.log('Subgraph latest block: ', +subgraphLatestBlock);
      console.log('User current block:', userCurrentBlock);
      console.log('Block sync obj:', blockSyncObj);
      console.log(' ');
    }

    try {
      if (subgraphLatestBlock >= +latestInteractionBlock) {
        dispatch({ synced: true });
        setIsSyncing(false);
        localStorage.removeItem('lastInteractionBlock');
      } else if (userCurrentBlock >= subgraphLatestBlock && !latestInteractionBlock) {
        dispatch({ synced: true });
        setIsSyncing(false);
      } else {
        dispatch({ synced: false });
        setIsSyncing(true);
      }
    } catch (error) {
      console.log(error);
      dispatch({ synced: false });
      setIsSyncing(false);
    }
  };

  const getSubgraphData = async (subgraphName: string, subgraphId: string): Promise<number> => {
    const subgraphState = await axios({
      url: BACKEND_URL,
      method: 'post',
      data: {
        query: `
                query($subgraphName: String!) {
                  indexingStatusesForSubgraphName(subgraphName: $subgraphName) {
                    chains {
                      ... on EthereumIndexingStatus {
                        latestBlock {
                          hash
                          number
                        }
                      }
                    }
                    subgraph
                  }
                }`,
        variables: {
          subgraphName,
        },
      },
    });

    const subgraphData = subgraphState.data.data?.indexingStatusesForSubgraphName.find((subgraph: any) => subgraph.subgraph === subgraphId);

    return +subgraphData?.chains[0].latestBlock.number;
  };

  let syncTimer: ReturnType<typeof setInterval>;

  useEffect(() => {
    (async () => {
      await syncBlocks();
    })();
    if (state.synced) {
      setIsSyncing(false);
      clearInterval(syncTimer);
    } else {
      setIsSyncing(true);
      syncTimer = setInterval(async () => {
        await syncBlocks();
      }, SYNC_TIMER);
    }
    return () => {
      clearInterval(syncTimer);
    };
  }, [state.synced, state.activeNetwork]);
}
