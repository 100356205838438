import { ethers } from 'ethers';
import Web3 from 'web3';

import { Contract } from 'web3-eth-contract';

// PROTECTION ADMIN ABI
const abi = require('../../abis/LosslessControllerV2.json');

export const getControllerContract = (provider: Web3, contractAddress: string): Contract => {
    return new provider.eth.Contract(abi, contractAddress);
}

export const getGnosisControllerContract = (provider: Web3, contractAddress: string): Contract => {
    return new provider.eth.Contract(abi, contractAddress);
}