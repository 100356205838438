import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import axios from 'axios';
import { FileDrop } from 'react-file-drop';

// NETWORKS
import { Chain, networks } from '../config/networks';

// STATE
import { useGlobalState } from '../index';

// ANTD
import { Form, Modal } from 'antd';
import Button from 'antd/lib/button';
import FileOutlined from '@ant-design/icons/lib/icons/FileOutlined';

// COMPONENTS
import ActiveAddress from '../shared/ActiveAddress';
import ActiveChain from '../shared/ActiveChain';
import ActiveToken from '../shared/ActiveToken';
import WhiteListTextArea from './WhiteListTextArea';

// STYLES
import './styles.scss';

// IMAGES
import upload from '../assets/images/upload.png';

// INTERFACES
import ActiveProtectionType from '../shared/ActiveProtectionType';
import { Controller } from '../Controller/interfaces/controller.interface';

// CONSTANTS
export const ADDRESS_NOT_VALID = 'There is some invalid addresses in this whitelist';
export const NOT_CSV = 'This is not a csv file!';
export const PROTECTED_ADDRESSES_ERRROR = 'It seems there was an error getting your protected addresses';

const Whitelist = ({ edit }: { edit: boolean }) => {
  const history = useHistory();
  const { address } = useParams<{ address: string }>();
  const [state, dispatch] = useGlobalState();
  const [loading, setLoadingState] = useState(false);
  const [whiteListLoading, setWhiteListLoading] = useState(false);
  const [whitelistAddressesState, setWhitelistAddressesState] = useState<string[]>([]);
  const Controller = state.controller as Controller;

  // MODALS
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalCSVFileVisible, setIsModalCSVFileVisible] = useState(false);

  const [form] = Form.useForm();
  const inputFile = useRef(null);

  // IS ADDRESS REG EXPRESSION
  const isAddress = new RegExp(/^0x[a-fA-F0-9]{40}$/);

  const navigateTo = (address: string): void => {
    history.push(address);
  };

  const handleCancel = (): void => {
    setIsModalVisible(false);
  };

  const handleCSVFileOk = (): void => {
    setIsModalCSVFileVisible(false);
  };

  const handleCSVFileCancel = (): void => {
    setIsModalCSVFileVisible(false);
  };

  const getWhitelistAddresses = async (token: string, protectedAddress: string): Promise<void> => {
    setLoadingState(true);

    const whitelistAddresses = await axios({
      url: networks.find((network: Chain) => network.id === parseInt(window.ethereum.chainId))?.url,
      method: 'post',
      data: {
        query: `
        query WhitelistAddresses(
          $token: Bytes!
          $protectedAddress: Bytes!
        ) {
          whitelistAddressesEvents(
            where: { token: $token, protectedAddress: $protectedAddress }
          ) {
            id
            protectedAddress
            state
            token
            whitelist
          }
        }`,
        variables: {
          token,
          protectedAddress,
        },
      },
    });

    setWhitelistAddressesState(whitelistAddresses.data.data.whitelistAddressesEvents[0]?.whitelist || []);

    form.setFieldsValue({
      whiteListAddresses: whitelistAddresses.data.data.whitelistAddressesEvents[0]?.whitelist.join('\n'),
    });

    setLoadingState(false);
  };

  const setWhitelistAddresses = async (): Promise<void> => {
    setWhiteListLoading(true);
    try {
      const addressesSpliting: string[] = form
        .getFieldValue('whiteListAddresses')
        .split('\n')
        .map((address: string) => address.toLowerCase())
        .filter((item: string, pos: number, arr: string[]) => item !== '' && arr.indexOf(item.toLowerCase()) === pos);

      const setWhitelistAddressResponse = await Controller.setProtectedAddress(state.selectedToken.address, address || state.addressToProtect, addressesSpliting);

      localStorage.setItem('lastInteractionBlock', setWhitelistAddressResponse.blockNumber.toString());

      dispatch({ synced: false });

      setWhiteListLoading(false);
      setIsModalVisible(false);

      history.push(`/whitelist/${address || state.addressToProtect}`);
    } catch (error: any) {
      if (error?.code === 4001) {
        setWhiteListLoading(false);
      } else {
        history.push('/Error', {
          title: 'Oops... Something went wrong.',
          message: 'There was an error setting up your whitelist addresses',
          address: address ? `/whitelist/${address}` : '/whitelist',
        });
      }
    }
  };

  const validateWhitelistAddresses = async (rule: any, addresses: string): Promise<void> => {
    addresses
      .split('\n')
      .filter((address: string) => address !== '')
      .forEach(whitelistAddress => {
        if (!isAddress.test(whitelistAddress)) throw new Error(ADDRESS_NOT_VALID);
      });
  };

  const validateUploadWhitelistAddresses = (addresses: string): boolean => {
    let valid = true;
    addresses
      .split('\n')
      .filter((address: string) => address !== '')
      .forEach(whitelistAddress => {
        if (!isAddress.test(whitelistAddress)) valid = false;
      });
    return valid;
  };

  useEffect((): void => {
    edit && state.selectedToken?.address && (address || state.addressToProtect) && getWhitelistAddresses(state.selectedToken.address, address ? address.toLowerCase() : state.addressToProtect.toLowerCase());

    if (address === undefined && state.addressToProtect === undefined) {
      history.push('/address-protection');
    }
  }, [state.addressToProtect, state.selectedToken?.address]);

  const setDropFile = (files: any): void => {
    if (files.length && files[0].type === 'text/csv') {
      var reader = new FileReader();
      reader.readAsBinaryString(files[0]);
      reader.onload = () => {
        const valid = validateUploadWhitelistAddresses(reader.result as string);
        if (valid) {
          const result = (reader.result as string).split('\n').filter((item: string, pos: number, arr: string[]) => item !== '' && arr.indexOf(item) === pos);

          setWhitelistAddressesState(result);
          form.setFieldsValue({ whiteListAddresses: result.join('\n') });
        } else {
          setWhitelistAddressesState([]);
          form.setFieldsValue({ whiteListAddresses: '' });
          toast.error(ADDRESS_NOT_VALID);
        }
        setIsModalCSVFileVisible(false);
      };
    } else if (files.length && files[0].type !== 'text/csv') {
      toast.warn(NOT_CSV);
    }
  };

  const onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setDropFile(event.target.files);
    (inputFile.current as any).value = null;
  };

  const selectFile = () => {
    (inputFile as any).current.click();
  };

  return (
    <>
      <Modal className='antd-modal whitelist-limit-modal' title='Whitelist addresses confirmation' visible={isModalVisible} onCancel={handleCancel} width={1000} footer={null}>
        <div className='whitelist-confirm-address-list-container'>
          {form
            .getFieldValue('whiteListAddresses')
            ?.split('\n')
            .map((address: string) => address.toLowerCase())
            .filter((item: string, pos: number, arr: string[]) => item !== '' && arr.indexOf(item) === pos)
            .map((whitelistAddress: string, i: number) => {
              return (
                <div className='whitelist-confirm-address-item' key={i}>
                  <span className='title'>Address {i + 1}</span>
                  <span className='value'>{whitelistAddress}</span>
                </div>
              );
            })}
        </div>
        <div className='actions-container'>
          <Button size='large' type='primary' onClick={() => setWhitelistAddresses()} loading={whiteListLoading}>
            Confirm
          </Button>
          <Button className='cancel' size='large' onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>
        </div>
      </Modal>
      <Modal className='antd-modal upload-csv-modal' title='Upload CSV File' visible={isModalCSVFileVisible} onOk={handleCSVFileOk} onCancel={handleCSVFileCancel} width={1000} footer={null}>
        <FileDrop className='drag-drop-container' onTargetClick={() => selectFile()} onDrop={(files, event) => setDropFile(files)}>
          <FileOutlined className='icon' />
          <span className='drop-desc'>Drop CSV File</span>
          <input type='file' id='file' ref={inputFile} onChange={onChangeFile} style={{ display: 'none' }} />
        </FileDrop>
        <div className='actions-container'>
          <Button className='cancel' size='large' onClick={() => setIsModalCSVFileVisible(false)}>
            Cancel
          </Button>
          <Button className='cancel' size='large' onClick={() => selectFile()}>
            Select File
          </Button>
        </div>
      </Modal>
      <div className='whitelist-container'>
        <Form form={form} layout='vertical'>
          <div className='content-box'>
            {edit ? <h3>Edit Whitelist</h3> : <h3>Whitelist</h3>}
            <p>Please enter one whitelist address per line.</p>
            <div className='active-state-container'>
              <ActiveChain />
              <ActiveToken />
              <ActiveProtectionType />
              <ActiveAddress address={address} />
            </div>
            <Form.Item
              label='List of Addresses'
              name='whiteListAddresses'
              rules={[
                {
                  validator: async (rule, value) => validateWhitelistAddresses(rule, value),
                },
              ]}
            >
              <div className='whitelist-list-container'>
                <WhiteListTextArea addresses={whitelistAddressesState} setWhitelistAddressesState={setWhitelistAddressesState} loading={loading}></WhiteListTextArea>
              </div>
            </Form.Item>
            <Button size='large' className='upload-btn' onClick={() => setIsModalCSVFileVisible(true)}>
              <img src={upload} />
              Upload CSV File
            </Button>
            <div className='br-line' />
            {edit ? (
              <Form.Item shouldUpdate>
                {() => (
                  <div className='whitelist-edit'>
                    <Button size='large' className='cancel-btn' type='primary' onClick={() => navigateTo(`/whitelist/${address}`)}>
                      Cancel
                    </Button>
                    <Button size='large' type='primary' onClick={() => setIsModalVisible(true)}>
                      Save
                    </Button>
                  </div>
                )}
              </Form.Item>
            ) : (
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    size='large'
                    type='primary'
                    onClick={() => setIsModalVisible(true)}
                    disabled={
                      !form
                        .getFieldValue('whiteListAddresses')
                        ?.split('\n')
                        .filter((address: string) => address !== '')
                        .filter((item: string, pos: number, arr: string[]) => arr.indexOf(item) === pos).length ||
                      !form.isFieldsTouched() ||
                      form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                    }
                  >
                    Next
                  </Button>
                )}
              </Form.Item>
            )}
          </div>
        </Form>
      </div>
    </>
  );
};

export default Whitelist;
