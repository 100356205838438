import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

// ANTD
import CaretRightOutlined from "@ant-design/icons/lib/icons/CaretRightOutlined";

// STATE
import { useGlobalState } from "../../index";

// IMAGES
import addressIcon from "../../assets/images/address-icon.png";

// STYLES
import "./styles.scss";

// UTILS
import { formatAddress } from "../../utils/format-address";

const ActiveAdmin = () => {
  const history = useHistory();
  const [state, dispatch] = useGlobalState();
  const [loading, setLoadingState] = useState(false);

  const isProtectionAdminSaved = async (): Promise<string | void> => {
    setLoadingState(true);
    try {
      const adminAddress = await state?.controller.protectionAdmin(
        state.selectedToken?.address
      );
      dispatch({ adminAddress });
      setLoadingState(false);
    } catch (error: any) {
      setLoadingState(false);
    }
  };

  const navigateTo = (): void => {
    history.push("/protection-admin");
  };

  useEffect(() => {
    state?.controller &&
      state.selectedToken?.address &&
      isProtectionAdminSaved();
  }, [state.selectedToken?.address, state?.controller]);

  return (
    <div
      className={`active-item-container ${
        state.networkConfig ? null : "disabled"
      }`}
    >
      <span className="active-item-icon">
        <img src={addressIcon} />
      </span>
      <span className="active-item-icon">
        Protection Admin:
        {loading ? (
          <span className="item-name address">Loading ...</span>
        ) : (
          <span className="item-name address" onClick={() => navigateTo()}>
            {state?.adminAddress
              ? formatAddress(state?.adminAddress as string)
              : "N/A"}
            <CaretRightOutlined />
          </span>
        )}
      </span>
    </div>
  );
};

export default ActiveAdmin;
