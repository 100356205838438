// CONFIG
import { NetworkConfig, networkConfigs } from "../config";

export const getActiveNetworkConfig = (
  chainId: number
): NetworkConfig | undefined => {
  const activeNetwork = networkConfigs.find(
    (networkConfig: NetworkConfig) => networkConfig.chainId === chainId
  );
  if (activeNetwork) return activeNetwork;
};
