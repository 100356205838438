import React from "react";

// STATE
import { useGlobalState } from "../../index";

// STYLES
import "./styles.scss";

const ActiveChain = () => {
  const [state, dispatch] = useGlobalState();

  return (
    <div
      className={`active-item-container ${
        state.networkConfig ? null : "disabled"
      }`}
    >
      <span className="active-item-icon">
        {state.activeNetwork?.icon ? (
          <img src={state.activeNetwork?.icon} />
        ) : (
          <span className="not-found">?</span>
        )}
      </span>
      <span className="active-item-icon">
        Network:{" "}
        <span className="item-name">
          {state.activeNetwork?.name
            ? state.activeNetwork?.name
            : "Non supported"}
        </span>
      </span>
    </div>
  );
};

export default ActiveChain;
