import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

// ANTD
import { Button } from "antd";

// IMAGES
import warning from "../assets/images/warning.svg";

// STYLES
import "./styles.scss";

const Error = () => {

  const history = useHistory<any>();

  const tryAgain = (address: string): void => {
    history.push(address);
  };

  return (
    <div className="error-container">
      <img src={warning} />
      <h2 className="error-header">{history.location.state?.title}</h2>
      <p>{history.location.state?.message}</p>
      <Button className="try-again" size="large" type="primary" onClick={() => tryAgain(history.location.state?.address)}>
        Try Again
      </Button>
    </div>
  );
};

export default Error;
