import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

// ANTD
import CaretRightOutlined from "@ant-design/icons/lib/icons/CaretRightOutlined";
import UnlockOutlined from "@ant-design/icons/lib/icons/UnlockOutlined";

// STATE
import { useGlobalState } from "../../index";

// STYLES
import "./styles.scss";

const ActiveProtectionType = () => {
  const history = useHistory();
  const [state, dispatch] = useGlobalState();
  const [activeProtectionType, setActiveProtectionType] =
    useState<string>("N/A");

  const setProtectionType = (): void => {
    if (state?.protectionType === state.networkConfig?.whiteListStrategy) {
      setActiveProtectionType("Whitelist");
    } else if (
      state.protectionType === state.networkConfig?.singleLimitStrategy
    ) {
      setActiveProtectionType("Single Limit");
    }
  };

  const navigateTo = (): void => {
    history.push("/protection-type");
  };

  useEffect(() => {
    state?.protectionType && state?.networkConfig && setProtectionType();
  }, [state?.protectionType, state?.networkConfig]);

  return (
    <div
      className={`active-item-container ${
        state.networkConfig ? null : "disabled"
      }`}
    >
      <div className="active-item-icon protection-type">
        <UnlockOutlined />
      </div>
      <span className="active-item-icon">
        Protection Type:{" "}
        <span className="item-name address" onClick={() => navigateTo()}>
          {activeProtectionType} <CaretRightOutlined />
        </span>
      </span>
    </div>
  );
};

export default ActiveProtectionType;
