import React from 'react';

// STYLES
import './styles.scss';

// IMAGES
import logo from '../../assets/images/vault-protection-logo.svg';

const Footer = ({ faqs = true }: { faqs?: boolean }) => {
  return (
    <div className='footer-container'>
      <div className='logo-container'>
        <a href='/'>
          <img src={logo} alt='Logo' />
        </a>
        <span className='copyright'>&#169;2024 Lossless</span>
      </div>
      <div className='footer-items'>
        <a href='https://docs.lossless.io/protocol/'>Whitepaper</a>
        {faqs ? <a href='https://docs.lossless.io/protocol/faq'>FAQs</a> : null}
        <a href='https://lossless.io/terms-of-use/' target='_blank'>
          Terms and Conditions
        </a>
        <a href='mailto: hello@lossless.cash'>Contact Us</a>
      </div>
    </div>
  );
};

export default Footer;
