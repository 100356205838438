import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

// STATE
import { useGlobalState } from '../../index';
// STYLES
import './styles.scss';

// IMAGES
import logo from '../../assets/images/vault-protection-logo.svg';
import ConnectWallet from '../ConnectWallet';

const Header = () => {
  const history = useHistory();
  const [state, dispatch] = useGlobalState();

  const navigateTo = (): void => {
    localStorage.removeItem('selectedTokenAddress');
    dispatch({ selectedToken: undefined });
    history.push('/select-token');
  };

  return (
    <>
      <div className='header-container'>
        <div className='logo-container'>
          <Link to='/'>
            <img src={logo} alt='Logo' />
          </Link>
        </div>
        <div className='menu-container'>
          <span onClick={() => navigateTo()}>Select Token</span>
          <Link to='/protected-addresses'>Protected Addresses</Link>
          <Link to='/protection-admin'>Protection Admin</Link>
        </div>
        <div className='connect-container'>
          <ConnectWallet showUserIcon={true} />
        </div>
      </div>
    </>
  );
};

export default Header;
