import React from "react";
import { useHistory } from "react-router";

// ANTD
import CaretRightOutlined from "@ant-design/icons/lib/icons/CaretRightOutlined";

// STATE
import { useGlobalState } from "../../index";

// IMAGES
import addressIcon from "../../assets/images/address-icon.png";

// STYLES
import "./styles.scss";

// UTILS
import { formatAddress } from "../../utils/format-address";

const ActiveAddress = ({ address }: { address?: string }) => {
  const history = useHistory();
  const [state, dispatch] = useGlobalState();

  const navigateTo = (): void => {
    history.push("/address-protection");
  };

  return (
    <div
      className={`active-item-container ${
        state.networkConfig ? null : "disabled"
      }`}
    >
      <span className="active-item-icon">
        <img src={addressIcon} />
      </span>
      <span className="active-item-icon">
        Protecting:{" "}
        <span className="item-name address" onClick={() => navigateTo()}>
          {address || state?.addressToProtect
            ? formatAddress(address || (state?.addressToProtect as string))
            : "N/A"}{" "}
          <CaretRightOutlined />
        </span>
      </span>
    </div>
  );
};

export default ActiveAddress;
