import Web3 from "web3";
import { BigNumber } from "ethers";

// CONFIG
import { NetworkConfig, networkConfigs } from "../config";

// CONTRACTS
import { getControllerContract } from "./contracts/controller.contract";
import { getGuardianContract } from "./contracts/guardian.contract";
import { getSingleLimitContract } from "./contracts/single-limit.contract";
import { getTokenContract } from "./contracts/token.contract";
import { getWhitelistContract } from "./contracts/whitelist.contract";

// INTERFACES
import {
  Controller,
  Limit,
  TransactionResponse,
} from "./interfaces/controller.interface";

export const Web3Controller = (
  networkConfig: NetworkConfig,
  account: string
): Controller => {
  const provider = new Web3(window.web3?.currentProvider);

  const ControllerContract = getControllerContract(
    provider,
    networkConfig.controller
  );

  const GuardianContract = getGuardianContract(
    provider,
    networkConfig.guardian
  );

  const SingleLimitContract = getSingleLimitContract(
    provider,
    networkConfig.singleLimitStrategy
  );

  const WhitelistContract = getWhitelistContract(
    provider,
    networkConfig.whiteListStrategy
  );

  return {
    tokenAdmin: async function (token: string): Promise<string> {
      const TokenContract = getTokenContract(provider, token);
      return TokenContract.methods.admin().call();
    },
    totalSupply: async function (token: string): Promise<string> {
      const TokenContract = getTokenContract(provider, token);
      return TokenContract.methods.totalSupply().call();
    },
    verifiedTokens: async function (token: string): Promise<boolean> {
      return GuardianContract.methods.verifiedTokens(token).call();
    },
    isAddressVerified: async function (
      token: string,
      address: string
    ): Promise<boolean> {
      return GuardianContract.methods.isAddressVerified(token, address).call();
    },
    getLimit: async function (token: string, address: string): Promise<Limit> {
      return SingleLimitContract.methods.getLimit(token, address).call();
    },
    setLimit: async function (
      token: string,
      address: string,
      limitPeriod: string,
      tokenAmount: BigNumber,
      startTime: number
    ): Promise<TransactionResponse> {
      return SingleLimitContract.methods
        .setLimit(token, address, limitPeriod, tokenAmount, startTime)
        .send({ from: account });
    },
    removeLimits: async function (
      token: string,
      address: string[]
    ): Promise<TransactionResponse> {
      return SingleLimitContract.methods
        .removeLimits(token, address)
        .send({ from: account });
    },
    removeProtectedAddresses: async function (
      token: string,
      address: string
    ): Promise<TransactionResponse> {
      return GuardianContract.methods
        .removeProtectedAddresses(token, address)
        .send({ from: account });
    },
    setProtectedAddress: async function (
      token: string,
      address: string,
      addresses: string[]
    ): Promise<TransactionResponse> {
      return WhitelistContract.methods
        .setProtectedAddress(token, address, addresses)
        .send({ from: account });
    },
    setProtectionAdmin: async function (
      token: string,
      adminWallet: string
    ): Promise<any> {
      return GuardianContract.methods
        .setProtectionAdmin(token, adminWallet)
        .send({ from: account });
    },
    setWhitelistState: async function (
      token: string,
      address: string,
      addresses: string[],
      state: boolean
    ): Promise<TransactionResponse> {
      return WhitelistContract.methods
        .setWhitelistState(token, address, addresses, state)
        .send({ from: account });
    },
    protectionAdmin: async function (token: string): Promise<string> {
      return GuardianContract.methods.protectionAdmin(token).call();
    },
    isAddressProtected: async function (
      token: string,
      protectedAddress: string
    ): Promise<boolean> {
      return ControllerContract.methods
        .isAddressProtected(token, protectedAddress)
        .call();
    },
  };
};
