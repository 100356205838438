import { toast } from 'react-toastify';

// UTILS
import { formatAddress } from './format-address';

export const copyText = (value: string | undefined = '') => {
    const inp = document.createElement('input');
    document.body.appendChild(inp);
    inp.value = value;
    inp.select();
    document.execCommand('copy', false);
    inp.remove();
    toast(`Address copied: ${formatAddress(value)}`);
}