import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

// STATE
import { useGlobalState } from '../index';

// ANTD
import { Form, Select } from 'antd';
import Button from 'antd/lib/button';

// COMPONENTS
import ActiveChain from '../shared/ActiveChain';

// STYLES
import './styles.scss';

// INTERFACES
import { Controller } from '../Controller/interfaces/controller.interface';
import { Token, tokens } from '../config';

// CONSTANTS
import { NOT_ADMIN_ADDRESS } from '../shared/constants';
import { TOKEN_IS_ADMIN, TOKEN_IS_VERIFIED, UNKNOWN_ERROR } from './constants';

const { Option } = Select;

const SelectToken = () => {
  const history = useHistory();
  const [loading, setLoadingState] = useState(false);
  const [tokenLoading, setTokenLoadingState] = useState(false);
  const [state, dispatch] = useGlobalState();
  const [tokensList, setTokensListState] = useState<Token[]>([]);
  const [form] = Form.useForm();
  const Controller = state.controller as Controller;

  const selectToken = async (account: string, selectedTokenAddress: string): Promise<void> => {
    setLoadingState(true);
    try {
      const selectedToken = tokensList.find((token: Token) => token.address === selectedTokenAddress);
      const isTokenVerified = await Controller.verifiedTokens(selectedTokenAddress);
      const tokenAdmin = await Controller.tokenAdmin(selectedTokenAddress);

      if (isTokenVerified) {
        const isProtectionAdmin = await Controller.protectionAdmin(selectedTokenAddress);
        localStorage.setItem('selectedTokenAddress', selectedToken?.address as string);
        setLoadingState(false);
        if (isProtectionAdmin === NOT_ADMIN_ADDRESS) {
          dispatch({ selectedToken, isFirstTime: true });
          history.push('/protection-admin');
        } else if (isProtectionAdmin === account) {
          dispatch({ selectedToken, isFirstTime: false });
          history.push('/protected-addresses');
        } else if (tokenAdmin === account) {
          dispatch({ selectedToken, isFirstTime: false });
          history.push('/protection-admin');
        }
      }
    } catch (error: any) {
      setLoadingState(false);
    }
  };

  const checkAccountState = async (account: string, tokenAddress: string): Promise<void> => {
    form.setFieldsValue({ selectedTokenAddress: tokenAddress });
    await form.validateFields();
    try {
      const isTokenVerified = await Controller.verifiedTokens(tokenAddress);
      if (isTokenVerified) {
        const isProtectionAdmin = await Controller.protectionAdmin(tokenAddress);
        const tokenAdmin = await Controller.tokenAdmin(tokenAddress);
        if (isProtectionAdmin !== NOT_ADMIN_ADDRESS && (tokenAdmin === account || isProtectionAdmin === account)) {
          history.push('/protected-addresses');
        } else if (isProtectionAdmin === NOT_ADMIN_ADDRESS && tokenAdmin === account) {
          history.push('/protection-admin');
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const verifyToken = async (selectedTokenAddress: string): Promise<boolean> => {
    try {
      const isTokenVerified = await Controller.verifiedTokens(selectedTokenAddress);
      if (isTokenVerified) return isTokenVerified;
      throw new Error(TOKEN_IS_VERIFIED);
    } catch (error: any) {
      setTokenLoadingState(false);
      throw new Error(TOKEN_IS_VERIFIED);
    }
  };

  const isProtectionAdmin = async (selectedTokenAddress: string): Promise<string> => {
    try {
      return Controller.protectionAdmin(selectedTokenAddress);
    } catch (error: any) {
      setTokenLoadingState(false);
      throw new Error(UNKNOWN_ERROR);
    }
  };

  const isTokenAdmin = async (selectedTokenAddress: string): Promise<string> => {
    try {
      return Controller.tokenAdmin(selectedTokenAddress);
    } catch (error: any) {
      setTokenLoadingState(false);
      throw new Error(TOKEN_IS_ADMIN);
    }
  };

  async function handleChange(rule: any, selectedTokenAddress: string): Promise<void> {
    setTokenLoadingState(true);
    form.setFieldsValue({ selectedTokenAddress });
    const isTokenVerified = await verifyToken(selectedTokenAddress);
    const protectionAdmin = await isProtectionAdmin(selectedTokenAddress);
    const tokenAdmin = await isTokenAdmin(selectedTokenAddress);

    if (isTokenVerified && (protectionAdmin === state.account || tokenAdmin === state.account)) {
      form.setFieldsValue({ selectedTokenAddress });
      setTokenLoadingState(false);
    } else {
      setTokenLoadingState(false);
      throw new Error(TOKEN_IS_ADMIN);
    }
  }

  const removeLastInteractionBlock = (): void => {
    localStorage.removeItem('lastInteractionBlock');
    if (!state.synced) dispatch({ synced: true });
  };

  const setTokensList = (networkId: number): void => {
    setTokensListState(tokens.filter((token: Token) => token.chainId === networkId));
  };

  useEffect((): void => {
    removeLastInteractionBlock();

    state?.account && state.selectedToken?.address && checkAccountState(state?.account, state.selectedToken?.address);
    state.activeNetwork && state.activeNetwork && setTokensList(state.activeNetwork.id);
  }, [state.activeNetwork, state.account, state.selectedToken?.address, state.networkConfig]);

  return (
    <div className='select-token-container'>
      <Form form={form}>
        <div className='content-box'>
          <h3>Select Token</h3>
          <p>
            Please select the token you want to protect.
            <br />
            Note that you should select the network on which your token is deployed using your wallet.
          </p>
          <div className='active-state-container'>
            <ActiveChain />
          </div>
          <Form.Item
            name='selectedTokenAddress'
            rules={[
              {
                validator: async (rule, value) => handleChange(rule, value),
              },
            ]}
          >
            <Select size='large' getPopupContainer={trigger => trigger.parentNode} loading={tokenLoading} disabled={tokenLoading || !state.account}>
              {tokensList.map((token: Token, i: number) => (
                <Option value={token.address} key={i}>
                  <img className='token-select-input-img' src={token.logoURI} alt={`${token.name} logo`} /> {token.displayName}
                  {' ('}
                  {token.address}
                  {')'}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <div className='br-line' />
          <Form.Item shouldUpdate>
            {() => (
              <Button
                size='large'
                type='primary'
                onClick={() => selectToken(state?.account, form.getFieldValue('selectedTokenAddress'))}
                disabled={!form.getFieldValue('selectedTokenAddress') || form.getFieldsError().filter(({ errors }) => errors.length).length > 0 || !form.isFieldsTouched() || tokenLoading}
                loading={loading}
              >
                Select Token
              </Button>
            )}
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default SelectToken;
