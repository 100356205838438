import React from "react";

// IMAGES
import notFoundImg from "../assets/images/not-found.png";

// STYLES
import "./styles.scss";

const NotFound = () => {
  return (
    <div className="not-found-container">
      <img src={notFoundImg} />
      <h2 className="not-found">Not Found</h2>
    </div>
  );
};

export default NotFound;
