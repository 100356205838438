import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { BigNumber, ethers } from 'ethers';

// PROVIDER
import { useWeb3 } from '../Context/';

// CONTROLLER
import { Controller } from '../Controller/interfaces/controller.interface';

// STATE
import { useGlobalState } from '../index';

// PIE CHART
import { PieChart } from 'react-minimal-pie-chart';

// ANTD
import { Form, Modal } from 'antd';
import Button from 'antd/lib/button';
import Countdown from 'antd/lib/statistic/Countdown';

// COMPONENTS
import ActiveChain from '../shared/ActiveChain';
import ActiveToken from '../shared/ActiveToken';
import ActiveAdmin from '../shared/ActiveAdmin';

// UTILS
import { copyText } from '../utils/copy';

// STYLES
import './styles.scss';

// ICONS
import LeftOutlined from '@ant-design/icons/lib/icons/LeftOutlined';
import editIcon from '../assets/images/edit.svg';
import expand from '../assets/images/expand.svg';
import hardResetIcon from '../assets/images/hard-reset.svg';
import removeIcon from '../assets/images/remove.svg';
import removeActiveIcon from '../assets/images/remove-active.svg';

// INTERFACES
import { AddressSingleLimit as IAddressSingleLimit, PieChartData } from './interfaces/address-single-limit.interface';

const AddressSingleLimit = () => {
  const { address } = useParams<{ address: string }>();
  const history = useHistory();
  const [form] = Form.useForm();
  const [isRemoveAddressModalVisible, setIsRemoveAddressModalVisible] = useState(false);
  const [hardResetLoading, setHardResetLoadingState] = useState(false);
  const [removeLoading, setRemoveLoadingState] = useState(false);
  const [state, dispatch] = useGlobalState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [addressSingleLimitState, setAddressSingleLimitState] = useState<IAddressSingleLimit>();
  const [countDownState, setCountDownState] = useState<number>();
  const [intervalState, setIntervalState] = useState<NodeJS.Timeout>();
  const [pieChartState, setPieChartState] = useState<PieChartData[]>([]);
  const { isConnected, isWeb3, accounts, network, networkId, currentAddress, web3, error } = useWeb3();

  const Controller = state.controller as Controller;
  const fixedTo = 6;

  const hardReset = async (): Promise<void> => {
    setHardResetLoadingState(true);
    try {
      const startTime = new Date().getTime() / 1000 + parseInt(addressSingleLimitState?.periodInSeconds as string);

      const amountPerPeriodFormated = addressSingleLimitState?.amountPerPeriod.replace('.', '') + setZeros(state.selectedToken.decimals, fixedTo);

      await Controller.setLimit(state.selectedToken.address, address, addressSingleLimitState?.periodInSeconds as string, BigNumber.from(amountPerPeriodFormated), Math.floor(startTime));

      await getAddressSingleLimit(address);

      setHardResetLoadingState(false);
      setIsModalVisible(false);
    } catch (error: any) {
      if (error?.code === 4001) {
        setHardResetLoadingState(false);
      } else {
        setHardResetLoadingState(false);
        setIsModalVisible(false);

        history.push('/Error', {
          title: 'Oops... Something went wrong.',
          message: 'There was an error reseting your limit',
          address: `/single-limit/${address}`,
        });
      }
    }
  };

  const setZeros = (tokenDecimals: number, fixedTo: number): string => new Array(tokenDecimals - fixedTo).fill(0).join('');

  const eventSubscriber = (tokenAddress: string): void => {
    web3?.eth.subscribe(
      'logs',
      {
        address: tokenAddress,
        topics: [ethers.utils.id('Transfer(address,address,uint256)')],
      },
      (error, result) => {
        if (!error) getAddressSingleLimit(address);
      },
    );
  };

  const checkCountDownState = (): void => {
    if (intervalState) clearInterval(intervalState);
    const newInterval = setInterval(() => {
      if (new Date().getTime() >= (countDownState as number)) {
        setAddressSingleLimitState({
          amountLeftInCurrentPeriod: addressSingleLimitState?.amountPerPeriod as string,
          amountPerPeriod: addressSingleLimitState?.amountPerPeriod as string,
          timeLeft: addressSingleLimitState?.timeLeft as number,
          periodInSeconds: addressSingleLimitState?.periodInSeconds as string,
        });

        setPieChartState([
          {
            title: 'Amount Left',
            value: parseInt(addressSingleLimitState?.amountPerPeriod as string),
            color: '#02ACD1',
          },
        ]);

        setCountDownState(new Date().getTime() + parseInt(addressSingleLimitState?.periodInSeconds as string) * 1000);
      }
    }, 1000);
    setIntervalState(newInterval);
  };

  const setNumberFormat = (tokenAmount: string, tokenDecimals: number, fixedTo: number): string => {
    return (parseInt(tokenAmount) / Math.pow(10, tokenDecimals)).toFixed(fixedTo);
  };

  const setIntegerFormat = (value: string, tokenDecimals: number): number => {
    if (BigNumber.isBigNumber(value)) {
      const integer = value.substring(0, value.length - tokenDecimals);
      return parseInt(integer) || 0;
    } else {
      return parseInt(value) / Math.pow(10, tokenDecimals);
    }
  };

  const getAddressSingleLimit = async (address: string): Promise<void> => {
    try {
      const addressSiglelimitResponse = await Controller.getLimit(state.selectedToken.address, address);

      const difference = Math.floor(new Date().getTime() / 1000) - parseInt(addressSiglelimitResponse.lastCheckpointTime);
      const periodsInDifference = difference / parseInt(addressSiglelimitResponse.periodInSeconds);
      const timeConsumed = periodsInDifference - Math.floor(periodsInDifference);
      const timeLeft = Math.floor((1 - timeConsumed) * parseInt(addressSiglelimitResponse.periodInSeconds));
      const countDownTimeStamp = new Date().getTime() + (timeLeft as number) * 1000;
      const lastCheckPoint = parseInt(addressSiglelimitResponse.lastCheckpointTime) + parseInt(addressSiglelimitResponse.periodInSeconds) - Math.floor(new Date().getTime() / 1000);

      setCountDownState(countDownTimeStamp);

      setAddressSingleLimitState({
        amountLeftInCurrentPeriod:
          lastCheckPoint > 0 ? setNumberFormat(addressSiglelimitResponse.amountLeftInCurrentPeriod, state.selectedToken.decimals, fixedTo) : setNumberFormat(addressSiglelimitResponse.amountPerPeriod, state.selectedToken.decimals, fixedTo),
        amountPerPeriod: setNumberFormat(addressSiglelimitResponse.amountPerPeriod, state.selectedToken.decimals, fixedTo),
        timeLeft,
        periodInSeconds: addressSiglelimitResponse.periodInSeconds,
      });

      const amountUsed = lastCheckPoint > 0 ? setIntegerFormat(addressSiglelimitResponse.amountLeftInCurrentPeriod, state.selectedToken.decimals) : setIntegerFormat(addressSiglelimitResponse.amountPerPeriod, state.selectedToken.decimals);

      const amountLeft = setIntegerFormat(addressSiglelimitResponse.amountPerPeriod, state.selectedToken.decimals) - setIntegerFormat(addressSiglelimitResponse.amountLeftInCurrentPeriod, state.selectedToken.decimals);

      const pieChartData = [
        {
          title: 'Amount Left',
          value: amountUsed,
          color: '#02ACD1',
        },
      ];

      if (amountLeft > 0 && lastCheckPoint > 0)
        pieChartData.push({
          title: 'Amount used',
          value: Math.floor(amountLeft),
          color: '#FFD96A',
        });

      setPieChartState(pieChartData);
    } catch (error: any) {
      history.push('/Error', {
        title: 'Oops... Something went wrong.',
        message: 'There was an error getting your limit configuration',
        address: `/single-limit/${address}`,
      });
    }
  };

  const removeProtection = async (): Promise<void> => {
    setRemoveLoadingState(true);
    try {
      const removeLimitsResponse = await Controller.removeLimits(state.selectedToken?.address, [address]);

      localStorage.setItem('lastInteractionBlock', removeLimitsResponse.blockNumber.toString());

      dispatch({ synced: false });

      setRemoveLoadingState(false);
      setIsRemoveAddressModalVisible(false);

      history.push('/protected-addresses');
    } catch (error: any) {
      if (error?.code === 4001) {
        setRemoveLoadingState(false);
      } else {
        history.push('/Error', {
          title: 'Oops... Something went wrong.',
          message: 'There was an error removing your limit configuration',
          address: `/single-limit/${address}`,
        });
        setRemoveLoadingState(false);
        setIsRemoveAddressModalVisible(false);
      }
    }
  };

  const navigateTo = (address: string): void => {
    history.push(address);
  };

  const handleCancel = (): void => {
    setIsModalVisible(false);
  };

  const handleRemoveAddressCancel = (): void => {
    setIsRemoveAddressModalVisible(false);
  };

  useEffect(() => {
    countDownState && addressSingleLimitState?.timeLeft && checkCountDownState();
  }, [countDownState, addressSingleLimitState?.timeLeft, addressSingleLimitState?.periodInSeconds]);

  useEffect(() => {
    address && state.controller && state.selectedToken?.decimals && getAddressSingleLimit(address);
    state.selectedToken?.address && eventSubscriber(state.selectedToken?.address);
  }, [state.controller, state.selectedToken]);

  return (
    <>
      <Modal className='antd-modal address-whitelist-modal' title='Are you sure you want to remove this protected address?' onCancel={handleRemoveAddressCancel} width={1000} visible={isRemoveAddressModalVisible} footer={null}>
        <p>After you remove this address from the list here, it will no longer be protected.</p>
        <div className='actions-container'>
          <Button size='large' type='primary' onClick={() => removeProtection()} loading={removeLoading}>
            Remove protected address
          </Button>
          <Button className='cancel' size='large' type='primary' onClick={() => setIsRemoveAddressModalVisible(false)}>
            Cancel
          </Button>
        </div>
      </Modal>
      <Modal className='antd-modal address-single-limit-modal' title='Are you sure you want to reset period now?' width={1000} onCancel={handleCancel} visible={isModalVisible} footer={null}>
        <p>Proceeding will leave Single Limit in place for this address. The period countdown will immediately restart from Period Length. Amount Left in Period will be reset to Amount Allowed per Period.</p>
        <div className='actions-container'>
          <Button size='large' type='primary' onClick={() => hardReset()} loading={hardResetLoading}>
            Confirm and Reset
          </Button>
          <Button className='cancel' size='large' type='primary' onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>
        </div>
      </Modal>
      <div className='address-single-limit-container'>
        <Form form={form}>
          <div className='content-box'>
            <h3>
              <LeftOutlined className='go-back' onClick={() => navigateTo('/protected-addresses')} />
              <span className='title' onClick={() => copyText(address)}>
                {address} <img src={expand} />
              </span>
            </h3>
            <p>Single Limit</p>
            <div className='active-state-container'>
              <ActiveChain />
              <ActiveToken />
              <ActiveAdmin />
            </div>
            <div className='actions-container'>
              <Button className={`single-limit-btn ${state?.account !== state?.adminAddress ? 'disabled' : null}`} size='large' onClick={() => navigateTo(`/single-limit/${address}/edit`)} disabled={state?.account !== state?.adminAddress}>
                <img src={editIcon} />
                Edit Limits
              </Button>
              <Button
                className={`remove-single-limit-btn ${state?.account !== state?.adminAddress ? 'disabled' : null}`}
                size='large'
                onClick={() => setIsRemoveAddressModalVisible(true)}
                loading={removeLoading}
                disabled={state?.account !== state?.adminAddress}
              >
                <img src={removeIcon} className='remove-icon' />
                <img src={removeActiveIcon} className='remove-active' />
                Remove Protection
              </Button>
            </div>
            <div className='content-info-container'>
              <div className='pie-chart-container'>
                <div className='pie-chart-content'>
                  {addressSingleLimitState?.amountLeftInCurrentPeriod && addressSingleLimitState?.amountPerPeriod ? (
                    <PieChart animate startAngle={100} labelPosition={60} label={({ dataEntry }) => `${Math.round(dataEntry.percentage)} %`} labelStyle={{ fontSize: '4px', fill: '#fff' }} data={pieChartState} />
                  ) : null}
                </div>
                <div className='pie-chart-legend'>
                  <ul>
                    <li>
                      <span className='bullet-color' style={{ backgroundColor: '#02ACD1' }}></span>
                      Amount left
                    </li>
                    <li>
                      <span className='bullet-color' style={{ backgroundColor: '#FFD96A' }}></span>
                      Amount used
                    </li>
                  </ul>
                </div>
              </div>
              <div className='hard-reset-container'>
                <div className='hard-reset-item'>
                  <span className='title'>Amount Left in Period</span>
                  <span className='value'>{addressSingleLimitState?.amountLeftInCurrentPeriod}</span>
                </div>
                <div className='hard-reset-item'>
                  <span className='title'>Amount Allowed per Period</span>
                  <span className='value'>{addressSingleLimitState?.amountPerPeriod}</span>
                </div>
                <div className='hard-reset-item'>
                  <span className='title'>Period Resets In</span>
                  <span className='value'>
                    <Countdown className='countdown' value={countDownState} format='D day HH:mm:ss' />
                  </span>
                </div>
                <div className='hard-reset-item'>
                  <span className='title'>Period Length</span>
                  <span className='value'>
                    {addressSingleLimitState?.periodInSeconds ? `${Math.floor(parseInt(addressSingleLimitState?.periodInSeconds) / 3600)}h ${Math.round(parseInt(addressSingleLimitState?.periodInSeconds) % 3600) / 60}min` : 'N/A'}
                  </span>
                </div>
                <Button className={`hard-reset-btn ${state?.account !== state?.adminAddress ? 'disabled' : null}`} onClick={() => setIsModalVisible(true)} disabled={state?.account !== state?.adminAddress}>
                  <img src={hardResetIcon} /> Reset Period Now
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddressSingleLimit;
