import Web3 from "web3";
import { Contract } from "web3-eth-contract";

// PROTECTION ADMIN ABI
const abi = require("../../abis/TreasuryProtectionStrategy.json");

export const getWhitelistContract = (
  provider: Web3,
  address: string
): Contract => {
  return new provider.eth.Contract(abi.abi, address);
};

export const getGnosisWhitelistContract = (
  provider: Web3,
  contractAddress: string
): Contract => {
  return new provider.eth.Contract(abi.abi, contractAddress);
};
