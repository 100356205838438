import React from 'react';

// ANTD
import CaretRightOutlined from '@ant-design/icons/lib/icons/CaretRightOutlined';

// STATE
import { useGlobalState } from '../../index';

// STYLES
import './styles.scss';
import { useHistory } from 'react-router';

const ActiveToken = () => {
  const [state, dispatch] = useGlobalState();
  const history = useHistory();
  const selectToken = (): void => {
    localStorage.removeItem('selectedTokenAddress');
    dispatch({ selectedToken: null });
    history.push('/select-token');
  };

  return (
    <div className={`active-item-container ${state.networkConfig ? null : 'disabled'}`}>
      <div className={`active-item-icon`}>{state.selectedToken?.logoURI ? <img src={state.selectedToken?.logoURI} /> : <span className='not-found'>?</span>}</div>
      <span className='active-item-icon'>
        Token:{' '}
        {state.selectedToken ? (
          <span className='item-name address' onClick={() => selectToken()}>
            {state.selectedToken?.displayName} <CaretRightOutlined />
          </span>
        ) : (
          <span className='item-name address' onClick={() => selectToken()}>
            Select Token <CaretRightOutlined />
          </span>
        )}
      </span>
    </div>
  );
};

export default ActiveToken;
