import React, { useContext, useEffect, useRef, useState } from 'react';
import Web3 from 'web3';

export type Web3ContextState = {
  isWeb3: boolean;
  isConnected: boolean;
  web3: Web3 | null;
  accounts: string[];
  network: string;
  networkId: number;
  currentAddress: string;
  error: Error | null;
  connect: () => void;
};

const Web3Context = React.createContext({} as Web3ContextState);

const Web3ContextProvider: React.FC = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [isWeb3, setIsWeb3] = useState(false);
  const [accounts, setAccounts] = useState<string[]>([]);
  const [network, setNetwork] = useState('');
  const [networkId, setNetworkId] = useState(0);
  const [currentAddress, setCurrentAddress] = useState('');
  const [error, setError] = useState<Error | null>(null);

  const web3Ref = useRef<Web3 | null>(null);

  useEffect(() => {
    if (window.ethereum) {
      web3Ref.current = new Web3(window.ethereum);
      window.ethereum.on('accountsChanged', (accounts: string[]) => {
        if (accounts.length > 0) {
          setAccounts(accounts);
          setCurrentAddress(Web3.utils.toChecksumAddress(accounts[0]));
        } else {
          setAccounts([]);
          setCurrentAddress('');
          setIsConnected(false);
        }
      });
      window.ethereum.on('networkChanged', (networkId: number) => {
        setNetworkId(+networkId);
      });
    }
  }, [networkId, accounts]);

  useEffect(() => {
    (async () => {
      try {
        let web3: Web3;
        let ethAccounts: string[];

        //  For most modern browsers
        if (window.ethereum) {
          web3 = new Web3(window.ethereum);
          window.web3 = web3;
          ethAccounts = await window.ethereum.request({ method: 'eth_accounts' });
          if (ethAccounts.length > 0) {
            setCurrentAddress(Web3.utils.toChecksumAddress(ethAccounts[0]));
          }
        }

        // Legacy browsers, Using MetaMask's provider.
        else if (window.web3) {
          web3 = window.web3;
          ethAccounts = await web3.eth.getAccounts();
        } else {
          throw new Error('No Ethereum providers found.');
        }
        if (ethAccounts.length > 0) {
          web3Ref.current = web3;
          setAccounts(ethAccounts);
          setCurrentAddress(Web3.utils.toChecksumAddress(ethAccounts[0]));
          const network = await web3.eth.net.getNetworkType();
          setNetwork(network);
          const networkId = await web3.eth.getChainId();
          setNetworkId(networkId);
          setIsWeb3(true);
          setIsConnected(true);
        }
      } catch (error) {
        setIsWeb3(false);
        setIsConnected(false);
        setError(error as Error);
        console.error('Web3 Context Error', error);
      }
    })();
    return () => {};
  }, []);

  const connect = async () => {
    let ethAccounts: string[];
    let web3: Web3;
    try {
      if (window.ethereum) {
        web3 = new Web3(window.ethereum);
        window.web3 = web3;
        ethAccounts = await window.ethereum?.request({
          method: 'eth_requestAccounts',
        });
      } else if (window.web3) {
        web3 = window.web3;
        ethAccounts = await web3.eth.getAccounts();
      } else {
        throw new Error('No Ethereum providers found.');
      }
      web3Ref.current = web3;
      setAccounts(ethAccounts);
      setCurrentAddress(Web3.utils.toChecksumAddress(ethAccounts[0]));
      const network = await web3.eth.net.getNetworkType();
      setNetwork(network);
      const networkId = await web3.eth.net.getId();
      setNetworkId(networkId);
      setIsWeb3(true);
      setIsConnected(true);
    } catch (error) {
      setIsConnected(false);
      setError(error as Error);
    }
  };

  return (
    <Web3Context.Provider
      value={{
        isConnected,
        isWeb3,
        accounts,
        network,
        networkId,
        currentAddress,
        web3: web3Ref.current,
        error,
        connect,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

const useWeb3 = () => {
  const context = useContext(Web3Context);

  return context;
};

export { Web3Context, Web3ContextProvider, useWeb3 };
