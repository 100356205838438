import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

// STATE
import { useGlobalState } from '../index';

// ANTD
import { Alert, Form, Input } from 'antd';
import Button from 'antd/lib/button';

// COMPONENTS
import ActiveAdmin from '../shared/ActiveAdmin';
import ActiveChain from '../shared/ActiveChain';
import ActiveToken from '../shared/ActiveToken';
import TokenAdmin from '../shared/TokenAdmin';

// STYLES
import './styles.scss';

// INTERFACES
import { Controller } from '../Controller/interfaces/controller.interface';

export enum AdminAddressSaved {
  ACTIVE = 'ACTIVE',
  SAVED = 'Protection Admin address saved!',
  ERROR = 'There was an error saving your Protection Admin address!',
  NOT_ADMIN = 'Your account is not the admin account',
}

export const NO_TOKEN = 'No token has been selected. Please select a token to continue.';

const ProtectionAdmin = () => {
  const history = useHistory();
  const [state, dispatch] = useGlobalState();
  const [loading, setLoadingState] = useState(false);
  const Controller = state.controller as Controller;

  // ETH ACCOUNT REGEX
  const isAddress = new RegExp(/^0x[a-fA-F0-9]{40}$/);

  const [form] = Form.useForm();

  const saveAdminAddress = async (tokenAddress: string, account: string, isFirstTime: boolean): Promise<void> => {
    setLoadingState(true);
    const adminAddress = form.getFieldValue('adminAddress');

    try {
      await Controller.setProtectionAdmin(tokenAddress, adminAddress);
      setLoadingState(false);
      toast(AdminAddressSaved.SAVED);

      if (isFirstTime && adminAddress === account) {
        dispatch({ adminAddress, isFirstTime: false });
        history.push('protection-type');
      } else if (!isFirstTime && adminAddress === account) {
        history.push('protected-addresses');
      } else if (adminAddress !== account) {
        dispatch({ adminAddress });
        toast.warn(AdminAddressSaved.NOT_ADMIN);
        // localStorage.removeItem('selectedTokenAddress');
        // history.push('select-token');
      }
    } catch (error: any) {
      if (error?.code === 4001) {
        setLoadingState(false);
      } else {
        setLoadingState(false);
        toast.warn(AdminAddressSaved.ERROR);
      }
    }
  };

  // const checkAccountState = async (): Promise<void> => {
  //   if (state.selectedToken?.address && state?.account && state?.controller) {
  //     try {
  //       const isTokenVerified = await controller.verifiedTokens(state.selectedToken.address);
  //       if (isTokenVerified) {
  //         const tokenAdmin = await state?.controller.tokenAdmin(state.selectedToken?.address);
  //         const isProtectionAdmin = await controller.protectionAdmin(state.selectedToken.address);
  //         if (tokenAdmin !== state.account && isProtectionAdmin !== state.account) {
  //           history.push('/Error', {
  //             title: 'Oops... Something went wrong.',
  //             message: 'There was an error verifying your token or your admin address is not related with the selected token',
  //             address: '/n',
  //           });
  //         }
  //       }
  //     } catch (error: any) {
  //       history.push('/Error', {
  //         title: 'Oops... Something went wrong.',
  //         message: 'There was an error verifying your token or your admin address is not related with the selected token',
  //         address: '/n',
  //       });
  //     }
  //   } else if (state?.account && !state.selectedToken?.address) {
  //     history.push("select-token");
  //   }
  // };

  const navigateTo = (route: string): void => {
    history.push(route);
  };

  // useEffect((): void => {
  // (state?.account || state.selectedToken?.address) && checkAccountState();
  // }, [state?.account, state?.tokenAdmin, state.selectedToken?.address, state?.controller]);

  return (
    <div className='protection-admin-container'>
      <Form form={form} layout='vertical'>
        <div className='content-box'>
          <h3>
            Protection Admin
            {state.isFirstTime && state.adminAddress !== '0x0000000000000000000000000000000000000000' ? (
              <Button size='large' className='protected-addresses-btn' onClick={() => navigateTo('/protected-addresses')}>
                Protected Addresses
              </Button>
            ) : null}
          </h3>
          <p>
            Here you can add or delete newly added protection addresses and set limits for them.
            <br />
            Protection Admin is responsible for vault or treasury protection side. Please setup an protection admin wallet.
          </p>
          <div className='active-state-container'>
            <ActiveChain />
            <ActiveToken />
            <TokenAdmin />
            <ActiveAdmin />
          </div>
          {!state.selectedToken ? <Alert className='info-message' description={NO_TOKEN} type='info' showIcon /> : null}

          <Form.Item
            name='adminAddress'
            label='Protection admin wallet'
            extra='Please note, in order to change the Protection Admin you need to do it from Token Admin account.'
            rules={[
              {
                required: true,
                message: 'A valid address is required!',
              },
              {
                message: 'This is not an address!',
                pattern: isAddress,
              },
            ]}
          >
            <Input size='large' placeholder='Your Admin Wallet address' disabled={state?.tokenAdmin !== state?.account}></Input>
          </Form.Item>

          <div className='br-line' />
          <Form.Item shouldUpdate>
            {() => (
              <Button
                size='large'
                type='primary'
                onClick={() => saveAdminAddress(state.selectedToken?.address, state?.account, state?.isFirstTime)}
                disabled={form.getFieldsError().filter(({ errors }) => errors.length).length > 0 || !form.isFieldsTouched() || state?.tokenAdmin !== state?.account}
                loading={loading}
              >
                Save
              </Button>
            )}
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default ProtectionAdmin;
