import Button from 'antd/lib/button';
import { useWeb3 } from '../../Context';
import { useGlobalState } from '../../index';

import userIcon from '../../assets/images/user-wallet.svg';
import './styles.scss';

import { formatAddress } from '../../utils/format-address';

const ConnectWallet: React.FC<{ showUserIcon?: boolean; onUserConnect?: () => void }> = ({ showUserIcon, onUserConnect }) => {
  const { isConnected, currentAddress, connect } = useWeb3();
  const [state, dispatch] = useGlobalState();
  const handleWalletConnect = async () => {
    connect();
    if (currentAddress) {
      dispatch({ account: currentAddress });
    }
  };

  return (
    <>
      {state?.account && isConnected ? (
        <Button type='primary' size='large' className='connect-wallet' icon={showUserIcon ? <img className='user-icon' src={userIcon} alt='User Icon' /> : null}>
          {formatAddress(currentAddress)}
        </Button>
      ) : (
        <Button type='primary' size='large' className='connect-wallet' onClick={() => handleWalletConnect()} icon={showUserIcon ? <img className='user-icon' src={userIcon} alt='User Icon' /> : null}>
          Connect Wallet
        </Button>
      )}
    </>
  );
};

export default ConnectWallet;
