import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// STATE
import { useGlobalState } from '../index';

// STYLES
import './styles.scss';

// INTERFACES
import { Controller } from '../Controller/interfaces/controller.interface';
import ConnectWallet from '../shared/ConnectWallet';

const Home = () => {
  const history = useHistory();
  const [state, dispatch] = useGlobalState();
  const Controller = state.controller as Controller;

  const checkAccountState = async (): Promise<void> => {
    if (state.selectedToken?.address && state?.account && state?.controller) {
      try {
        const isTokenVerified = await Controller.verifiedTokens(state.selectedToken.address);
        if (isTokenVerified) {
          const isProtectionAdmin = await Controller.protectionAdmin(state.selectedToken.address);
          const tokenAdmin = await state?.controller.tokenAdmin(state.selectedToken?.address);
          if (isProtectionAdmin === state.account) {
            history.push('/protected-addresses');
          } else if (isProtectionAdmin !== state.account && tokenAdmin === state.account) {
            history.push('/protection-admin');
          }
        }
      } catch (error: any) {
        history.push('/select-token');
      }
    } else if (state?.account) {
      history.push('select-token');
    }
  };

  useEffect((): void => {
    (state?.account || state.selectedToken?.address) && checkAccountState();
  }, [state?.account, state.selectedToken?.address, state?.controller]);

  return (
    <div className='main-container'>
      <div className='content'>
        <h1 className='title'>
          Welcome to Lossless Vault <br />
          and treasury protection.
        </h1>
        <p className='desc'>
          Please, connect your admin wallet address to begin and{' '}
          <a href='https://lossless-cash.gitbook.io/lossless/technical-reference/vault-and-treasury-protection' className='documents-link'>
            all documents can be found here.
          </a>
        </p>
        <ConnectWallet showUserIcon={false} />
      </div>
    </div>
  );
};

export default Home;
